export const TYPES = {
  FETCH_FOLDER_CONTENT_STARTED: 'FETCH_FOLDER_CONTENT_STARTED',
  FETCH_FOLDER_CONTENT_FAILED: 'FETCH_FOLDER_CONTENT_FAILED',
  FETCH_FOLDER_CONTENT_COMPLETED: 'FETCH_FOLDER_CONTENT_COMPLETED',

  TOGGLE_SELECT_ALL: 'TOGGLE_SELECT_ALL',

  DELETE_FOLDER_CONTENT_STARTED: 'DELETE_FOLDER_CONTENT_STARTED',
  DELETE_FOLDER_CONTENT_FAILED: 'DELETE_FOLDER_CONTENT_FAILED',

  DELETE_FOLDER_CONTENT_COMPLETED: 'DELETE_FOLDER_CONTENT_COMPLETED',

  SAVE_REORDER_FOLDER_CONTENT_STARTED: 'SAVE_REORDER_FOLDER_CONTENT_STARTED',
  SAVE_REORDER_FOLDER_CONTENT_FAILED: 'SAVE_REORDER_FOLDER_CONTENT_FAILED',
  SAVE_REORDER_FOLDER_CONTENT_COMPLETED:
    'SAVE_REORDER_FOLDER_CONTENT_COMPLETED',
  REORDER_FOLDER_CONTENT: 'REORDER_FOLDER_CONTENT',

  FILTER_KEYWORD_CHANGED: 'FILTER_KEYWORD_CHANGED',
};
