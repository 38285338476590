import { createAction } from 'redux-actions';

export const TYPES = {
  STOCK_ITEM_EXPORT_REQUESTED: 'STOCK_ITEM_EXPORT_REQUESTED',
  STOCK_ITEM_EXPORT_REQUEST_SUCCEEDED: 'STOCK_ITEM_EXPORT_REQUEST_SUCCEEDED',
  STOCK_ITEM_EXPORT_REQUEST_FAILED: 'STOCK_ITEM_EXPORT_REQUEST_FAILED',
};

const stockItemExportRequested = createAction(
  TYPES.STOCK_ITEM_EXPORT_REQUESTED
);
const stockItemExportRequestSucceeded = createAction(
  TYPES.STOCK_ITEM_EXPORT_REQUEST_SUCCEEDED
);
const stockItemExportRequestFailed = createAction(
  TYPES.STOCK_ITEM_EXPORT_REQUEST_FAILED,
  (err) => err
);

export {
  stockItemExportRequested,
  stockItemExportRequestSucceeded,
  stockItemExportRequestFailed,
};
