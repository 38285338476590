import { PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { StockItemDetails } from '../../common/types/StockItemTypes';
import { StoryboardResponse, StoryboardScene } from '../types';

interface BasePayload {
  hash: string;
}

export const STORYBOARD_PARAMETERS_CHOSEN = 'PARAMETERS_CHOSEN';
export const STORYBOARD_REQUEST_INITIATED = 'REQUEST_INITIATED';
export const STORYBOARD_REQUEST_COMPLETED = 'REQUEST_COMPLETED';
export const STORYBOARD_SCENE_UPDATED = 'UPDATE_SCENE';
export const MUSIC_RECS_UPDATED = 'UPDATE_MUSIC';

export type StoryboardAction =
  | PayloadAction<BasePayload, typeof STORYBOARD_PARAMETERS_CHOSEN>
  | PayloadAction<BasePayload, typeof STORYBOARD_REQUEST_INITIATED>
  | PayloadAction<
      BasePayload & {
        response: StoryboardResponse;
      },
      typeof STORYBOARD_REQUEST_COMPLETED
    >
  | PayloadAction<
      BasePayload & { scene: StoryboardScene },
      typeof STORYBOARD_SCENE_UPDATED
    >
  | PayloadAction<
      BasePayload & { musicRecommendations: StockItemDetails[] },
      typeof MUSIC_RECS_UPDATED
    >;

export type StoryboardDispatch = Dispatch<StoryboardAction>;
export const useStoryboardDispatch = useDispatch<StoryboardDispatch>;

export const storyboardParametersChosen = (hash: string): StoryboardAction => ({
  type: STORYBOARD_PARAMETERS_CHOSEN,
  payload: { hash },
});

export const storyboardRequestInitiated = (hash: string): StoryboardAction => ({
  type: STORYBOARD_REQUEST_INITIATED,
  payload: { hash },
});

export const storyboardRequestCompleted = (
  hash: string,
  response: StoryboardResponse
): StoryboardAction => ({
  type: STORYBOARD_REQUEST_COMPLETED,
  payload: { hash, response },
});

export const storyboardSceneUpdated = (
  hash: string,
  scene: StoryboardScene
): StoryboardAction => ({
  type: STORYBOARD_SCENE_UPDATED,
  payload: { hash, scene },
});

export const musicRecommendationsUpdated = (
  hash: string,
  musicRecommendations: StockItemDetails[]
): StoryboardAction => ({
  type: MUSIC_RECS_UPDATED,
  payload: { hash, musicRecommendations },
});
