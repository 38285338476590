import merge from 'lodash/merge';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import thunkMiddleware from 'redux-thunk';

import audioReducer from '../Audio/reducers/audioReducer';
import BrowseStateDefinition from '../Browse/BrowseStateDefinition';
import browseReducer from '../Browse/reducers/browseReducer';
import DetailsStateDefinition from '../Details/DetailsStateDefinition';
import detailsReducer from '../Details/reducers/detailsReducer';
import MediaTypeSearchStateDefinition from '../MediaTypeSearch/MediaTypeSearchStateDefinition';
import mediaTypeSearchReducer from '../MediaTypeSearch/reducers/mediaTypeSearchReducer';
import cancelReducer from '../Member/Cancel/CancelReducer';
import cancelStateDefinition from '../Member/Cancel/CancelStateDefinition';
import FolderFeedbackStateDefinition from '../Member/Folders/FolderFeedbackStateDefinition';
import folderFeedbackReducer from '../Member/Folders/reducers/folderFeedbackReducer';
import FolderStateDefinition from '../Member/MemberBin/FolderStateDefinition';
import folderReducer from '../Member/MemberBin/folderReducer';
import FoldersStateDefinition from '../Member/MemberBins/FoldersStateDefinition';
import { persistFoldersMiddleware } from '../Member/MemberBins/middleware/persistFoldersMiddleware';
import foldersReducer from '../Member/MemberBins/reducers/foldersReducer';
import npsModalLoaderReducer from '../NPSModalLoader/NPSModalLoaderReducer';
import NPSModalLoaderStateDefinition from '../NPSModalLoader/NPSModalLoaderStateDefinition';
import signUpContainerReducer from '../PaymentMethodForms/SignUp/SignUpContainerReducer';
import signUpStateDefinition from '../PaymentMethodForms/SignUp/SignUpStateDefinition';
import planSelectCardsContainerReducer from '../PlanSelectCards/PlanSelectCardsContainerReducer';
import PopUpsStateDefinition from '../PopUps/PopUpsStateDefinition';
import popUpsReducer from '../PopUps/reducers/PopUpsReducer';
import StoryboardStateDefinition from '../Storyboards/StoryboardStateDefinition';
import { persistStoryboardMiddleware } from '../Storyboards/middleware/persistStoryboardMiddleware';
import storyboardReducer from '../Storyboards/reducers/storyboardReducer';
import userSignupReducer from '../UserSignup/UserSignupReducer';
import UserSignupStateDefinition from '../UserSignup/UserSignupStateDefinition';
import appReducer from '../app/AppReducer';
import AppStateDefinition from '../app/AppStateDefinition';
import SearchOptions from '../app/Search/entities/SearchOptions';
import authReducer from '../auth/AuthReducer';
import AuthStateDefinition from '../auth/AuthStateDefinition';
import UiReducer from '../ui/UiReducer';
import UiStateDefinition from '../ui/UiStateDefinition';

declare const window: Window & {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  Cypress?: object;
  __store__?: Store;
};

export default function configureStore(preloadedState, history) {
  history = history || {};

  const isDevelopmentEnvironment = process.env.NODE_ENV === 'development';

  const { routerMiddleware, routerReducer } = createReduxHistoryContext({
    history,
    reduxTravelling:
      (typeof __SITE_ENV__ !== 'undefined' ? __SITE_ENV__ : '') !== 'prod', // i.e. SiteEnvironmentEnum.Prod
  });

  function preprocessPreloadedState(preloadedState) {
    // Convert selectedSearchFilterOptions to entity object
    const searchOptionParameters =
      preloadedState?.app?.search?.selectedSearchFilterOptions;
    if (searchOptionParameters) {
      preloadedState.app.search.selectedSearchFilterOptions =
        SearchOptions.getDefaultSearchOptions().update(searchOptionParameters);
    }

    return preloadedState;
  }

  const reducers = {
    app: appReducer,
    auth: authReducer,
    ui: UiReducer,
    details: detailsReducer,
    popUps: popUpsReducer,
    npsModal: npsModalLoaderReducer,
    routing: routerReducer,
    mediaTypeSearch: mediaTypeSearchReducer,
    audio: audioReducer,
    memberBin: folderReducer,
    memberBins: foldersReducer,
    folderFeedback: folderFeedbackReducer,
    planSelectCards: planSelectCardsContainerReducer,
    browse: browseReducer,
    userSignup: userSignupReducer,
    builder: (state = {}) => state,
    signUp: signUpContainerReducer,
    cancel: cancelReducer,
    storyboard: storyboardReducer,
  };

  let loggerMiddleware = null;
  if (isDevelopmentEnvironment) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    loggerMiddleware = require('redux-logger').createLogger();
  }

  const middleware = [
    thunkMiddleware,
    loggerMiddleware,
    routerMiddleware,
    persistFoldersMiddleware,
    persistStoryboardMiddleware,
  ].filter(Boolean);

  const composeEnhancers =
    (isDevelopmentEnvironment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const initialState = merge(
    {
      app: AppStateDefinition.getInitialState(),
      auth: AuthStateDefinition.getInitialState(),
      //entities: EntitiesStateDefinition.getInitialState(), //TODO [ReduxReorg] Uncomment when we have entities store component
      ui: UiStateDefinition.getInitialState(),
      details: DetailsStateDefinition.getInitialState(),
      mediaTypeSearch: MediaTypeSearchStateDefinition.getInitialState(),
      memberBin: FolderStateDefinition.getInitialState(),
      memberBins: FoldersStateDefinition.getInitialState(),
      folderFeedback: FolderFeedbackStateDefinition.getInitialState(),
      popUps: PopUpsStateDefinition.getInitialState(),
      npsModal: NPSModalLoaderStateDefinition.getInitialState(),
      browse: BrowseStateDefinition.getInitialState(),
      userSignup: UserSignupStateDefinition.getInitialState(),
      signUp: signUpStateDefinition.getInitialState(),
      cancel: cancelStateDefinition.getInitialState(),
      storyboard: StoryboardStateDefinition.getInitialState(),
    },
    preprocessPreloadedState(preloadedState)
  );

  const store = createStore(
    combineReducers(reducers),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  if (window.Cypress) {
    window.__store__ = store;
  }

  return store;
}
