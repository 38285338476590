import { NPSModalLoaderReducerState } from './NPSModalLoaderTypes';

class NPSModalLoaderStateDefinition {
  static getInitialState(): NPSModalLoaderReducerState {
    return {
      siteFullName: '',
      surveyId: '',
      context: '',
      responsePromise: null,
    };
  }
}

export default NPSModalLoaderStateDefinition;
