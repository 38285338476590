// use React rendering service and pass in object (above)
import { OrderSummary } from './OrderSummaryDefinition';

interface FieldLabels {
  stateLabel?: string;
  zipLabel?: string;
  cvvLabel: string;
  defaultStateValue?: string;
  defaultCountryValue?: string;
  pinLabel?: string;
}

interface ObjNameAbbr {
  abbreviation?: string;
  name?: string;
}

export interface CreditCardConfiguration {
  shouldShowAddressFields: boolean;
  shouldShowCvvDescription: boolean;
  shouldShowZipFieldOnly: boolean;
  shouldShowNameField: boolean;
  useTextTooltipForCvvPopup: boolean;
  shouldShowPinField: boolean;
  googleCaptchaKey?: string;
  googleCaptchaSecondaryV2Key?: string;
  postNewCreditCardOnSave: boolean;
  fieldLabels: FieldLabels;
  countries: ObjNameAbbr[];
  states: ObjNameAbbr[];
  braintreeClientToken: string;
}

interface Configuration {
  braintreeClientToken: string;
  paypalClientId: string;
  paypalEnvironment: string;
}

interface PaypalConfiguration {
  configuration?: Configuration;
  paymentMethodLabel?: string;
  paymentMethodNonceLabel?: string;
  firstNameLabel?: string;
  lastNameLabel?: string;
  shouldShowPaypalButton?: boolean;
}

export interface PaymentMethodConfiguration {
  creditCard?: CreditCardConfiguration;
  paypal?: PaypalConfiguration;
}

export interface PaymentToken {
  isTokenDeleted?: boolean;
  isTokenValid?: boolean;
  paymentMethodType?: number;
  subscriptionTitle?: string;
  tokenDetails?: TokenDetails;
  address?: Address;
}

interface TokenDetails {
  creditCardLastFour: string;
  creditCardExpMonth: number;
  creditCardExpYear: number;
  creditCardType: string;
}

interface Address {
  country: string;
  postalCode: string;
}

export type SignUpState = {
  orderSummary: OrderSummary;
  selectedPlanUniqueId: string;

  // common
  isAnnualSelected: boolean;

  // existing payment method
  paymentToken: PaymentToken;
  updatePaymentMethodRoute: string;

  paymentMethodConfiguration: PaymentMethodConfiguration;

  errorMessage?: string;

  // new payment method
  planTelemetryName?: string;

  plan: string[];
  downloadLimit: number;

  createSubscriptionRoute: string;
  createUserRoute: string;
  planUniqueIdSelector: string;

  isFetchingOrderUpdate: boolean;
  isProcessingSubscriptionRequest: boolean;
  needsFinalConfirmation: boolean;
  isExistingUser: boolean;
  isSignUpOnly: boolean;
  redirectAfterRegister: string;
};

class SignUpStateDefinition {
  static getInitialState(): SignUpState {
    return {
      orderSummary: null,
      isAnnualSelected: true,
      selectedPlanUniqueId: '',
      paymentToken: {},
      updatePaymentMethodRoute: '',
      paymentMethodConfiguration: {},
      plan: [],
      downloadLimit: null,
      createSubscriptionRoute: '',
      createUserRoute: '',
      planUniqueIdSelector: '',
      isFetchingOrderUpdate: false,
      isProcessingSubscriptionRequest: false,
      needsFinalConfirmation: false,
      isExistingUser: null,
      isSignUpOnly: true,
      redirectAfterRegister: '/pricing',
    };
  }
}

export default SignUpStateDefinition;
