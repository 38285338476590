import { createAction } from 'redux-actions';

import { PopupContext } from '../PopUpsTypes';

export const TYPES = {
  SHOW_POP_UP: 'SHOW_POP_UP',
  HIDE_POP_UP: 'HIDE_POP_UP',
};

export const hidePopUp = createAction(TYPES.HIDE_POP_UP);

export const showPopUp = (popUpName: string, context: PopupContext = {}) => ({
  type: TYPES.SHOW_POP_UP,
  popUpName,
  context,
});
