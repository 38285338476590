import { createBrowserHistory, History } from 'history';

export default class BrowserHistorySingleton {
  private static instance: History;

  static getInstance(): History {
    if (this.instance) {
      return this.instance;
    }

    this.instance = createBrowserHistory();
    this.instance.listen(() => {
      // Fire a custom event to let microfrontends know that the current location has changed.
      window.dispatchEvent(new CustomEvent('locationchanged'));
    });

    return this.instance;
  }
}
