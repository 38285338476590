import update, { Spec } from 'immutability-helper';
import omit from 'lodash/omit';

import { TYPES as FOLDER_TYPES } from '../../MemberBin/types';
import FoldersStateDefinition, {
  FoldersState,
} from '../FoldersStateDefinition';
import { TYPES } from '../FoldersTypes';

function foldersReducer(
  state = FoldersStateDefinition.getInitialState(),
  action
) {
  switch (action.type) {
    case TYPES.ADD_STOCK_ITEM_TO_FOLDER_STARTED:
      return state;

    case TYPES.ADD_STOCK_ITEM_TO_FOLDER_FAILED:
    case TYPES.ADD_STOCK_ITEM_TO_FOLDER_COMPLETED: {
      const updates: Spec<FoldersState> = {};

      if (action.payload.bins) {
        updates.bins = {
          $merge: action.payload.bins,
        };
      }

      return update(state, updates);
    }

    case TYPES.ADD_NEW_FOLDER_WITH_STOCK_ITEM_STARTED:
      return state;

    case TYPES.ADD_NEW_FOLDER_WITH_STOCK_ITEM_FAILED:
    case TYPES.ADD_NEW_FOLDER_WITH_STOCK_ITEM_COMPLETED:
      return update(state, {
        bins: {
          $merge: action.payload.bins,
        },
        addBinSuccess: {
          $set: action.payload.success,
        },
      });

    case TYPES.FETCH_ALL_FOLDERS_STARTED:
      return state;

    case TYPES.FETCH_ALL_FOLDERS_FAILED:
      return state;

    case TYPES.FETCH_ALL_FOLDERS_COMPLETED:
      return update(state, {
        isLoading: {
          $set: false,
        },
        bins: {
          $set: action.payload.bins,
        },
        organizationName: {
          $set: action.payload.organizationName,
        },
        isInSharedFoldersBeta: {
          $set: action.payload.isInSharedFoldersBeta,
        },
        hasEnterpriseSupport: {
          $set: action.payload.hasEnterpriseSupport,
        },
      });

    case TYPES.UPDATE_FOLDER_NAME_STARTED:
    case TYPES.UPDATE_FOLDER_ACCESS_CONTROL_STARTED:
      return update(state, {
        bins: {
          [action.payload]: {
            isUpdating: { $set: true },
          },
        },
      });

    case TYPES.UPDATE_FOLDER_NAME_FAILED:
    case TYPES.UPDATE_FOLDER_ACCESS_CONTROL_FAILED:
      return update(state, {
        bins: {
          [action.payload]: {
            isUpdating: { $set: false },
          },
        },
      });

    case TYPES.UPDATE_FOLDER_NAME_COMPLETED:
    case TYPES.UPDATE_FOLDER_ACCESS_CONTROL_COMPLETED:
      return update(state, {
        bins: {
          [action.payload.uniqueId]: {
            $set: action.payload,
          },
        },
      });

    case TYPES.DELETE_FOLDER_STARTED:
      return update(state, {
        bins: {
          [action.payload]: {
            isUpdating: { $set: true },
          },
        },
      });

    case TYPES.DELETE_FOLDER_FAILED:
      return update(state, {
        bins: {
          [action.payload]: {
            isUpdating: { $set: false },
          },
        },
      });

    case TYPES.DELETE_FOLDER_COMPLETED:
      return update(state, {
        bins: { $set: omit(state.bins, [action.payload]) },
      });

    case TYPES.CLONE_FOLDER_STARTED:
      return update(state, {
        bins: {
          [action.payload]: {
            isUpdating: { $set: true },
          },
        },
      });

    case TYPES.CLONE_FOLDER_FAILED:
      return update(state, {
        bins: {
          [action.payload]: {
            isUpdating: { $set: false },
          },
        },
      });

    case TYPES.CLONE_FOLDER_COMPLETED:
      const updatedBins = { ...state.bins, ...action.payload.newBin };
      updatedBins[action.payload.oldBinUniqueId].isUpdating = false;

      return {
        ...state,
        bins: updatedBins,
      };

    case FOLDER_TYPES.DELETE_FOLDER_CONTENT_COMPLETED:
      return update(state, {
        bins: {
          $merge: action.payload.bins,
        },
      });

    default:
      return state;
  }
}

export default foldersReducer;
