import { UiReducerState } from './UiTypes';

class UiStateDefinition {
  static getInitialState(): UiReducerState {
    return {
      nav: {
        menu: {
          top_level_categories: {},
          showFavorites: false,
          isOpen: false,
        },
      },
    };
  }
}

export default UiStateDefinition;
