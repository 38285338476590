import { TYPES as FOLDERS_TYPES } from '../../Folders/actions/FoldersActions';
import { TYPES as MEDIA_TYPE_TAB_TYPES } from '../../MediaTypeSearch/actions/MediaTypeTabsActions';
import MediaTypeSearchStateDefinition from '../MediaTypeSearchStateDefinition';

function updateStockItemWrapper(
  state,
  action,
  identityFunction,
  stockItemWrapperPropsToUpdate
) {
  if (action.payload.context !== 'mediaType') {
    return state;
  }

  const activeTabIdx = state.activeTab;
  const tabs = state.tabs;

  return {
    ...state,
    tabs: tabs.map((tab, idx) => {
      if (idx === activeTabIdx && tab.type === 'stockItems') {
        tab.content = tab.content.map((stockItemWrapper) => {
          if (identityFunction(stockItemWrapper.stockItem)) {
            stockItemWrapper = {
              ...stockItemWrapper,
              ...stockItemWrapperPropsToUpdate,
            };
          }
          return stockItemWrapper;
        });
      }
      return tab;
    }),
  };
}

function mediaTypeSearchReducer(
  state = MediaTypeSearchStateDefinition.getInitialState(),
  action
) {
  switch (action.type) {
    case FOLDERS_TYPES.ADD_TO_FOLDER_COMPLETED:
      return updateStockItemWrapper(
        state,
        action,
        (stockItem) => stockItem.contentId === action.payload.contentId,
        {
          isSavingFavorite: false,
          isFavorite: true,
        }
      );

    case FOLDERS_TYPES.REMOVE_FROM_FOLDER_COMPLETED:
      return updateStockItemWrapper(
        state,
        action,
        (stockItem) => stockItem.contentId === action.payload.contentId,
        {
          isSavingFavorite: false,
          isFavorite: false,
        }
      );

    case MEDIA_TYPE_TAB_TYPES.ACTIVE_TAB_CHANGED:
      return {
        ...state,
        activeTab: action.payload,
      };

    default:
      return state;
  }
}

export default mediaTypeSearchReducer;
