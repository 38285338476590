import { createAction } from 'redux-actions';

import { StockItemArtistInterface } from '../../app/Search/containers/MenuContainerInterfaces';
import {
  StockItem,
  StockItemContext,
  StockItemFormat,
} from '../../common/types/StockItemTypes';
import { WaveformDataInterface } from '../AudioTypes';

export const TYPES = {
  AUDIO_PLAY: 'AUDIO_PLAY',
  AUDIO_PAUSE: 'AUDIO_PAUSE',
  AUDIO_SET_SOURCE: 'AUDIO_SET_SOURCE',
  AUDIO_SET_CURRENT_TIME: 'AUDIO_SET_CURRENT_TIME',
  AUDIO_SET_CURRENT_VOLUME: 'AUDIO_SET_CURRENT_VOLUME',
  AUDIO_CLEAR_PENDING_VALUES: 'AUDIO_CLEAR_PENDING_VALUES',
  AUDIO_SET_PENDING_PLAY: 'AUDIO_SET_PENDING_PLAY',
  AUDIO_SET_PENDING_PAUSE: 'AUDIO_SET_PENDING_PAUSE',
  AUDIO_SET_PENDING_CURRENT_TIME: 'AUDIO_SET_PENDING_CURRENT_TIME',
  AUDIO_SET_PENDING_CURRENT_VOLUME: 'AUDIO_SET_PENDING_CURRENT_VOLUME',
  AUDIO_SET_PENDING_SOURCE: 'AUDIO_SET_PENDING_SOURCE',
  AUDIO_PLAY_CARD: 'AUDIO_PLAY_CARD',
  AUDIO_SET_PENDING_PLAY_NEXT: 'AUDIO_SET_PENDING_PLAY_NEXT',
  AUDIO_SET_PENDING_PLAY_PREV: 'AUDIO_SET_PENDING_PLAY_PREV',
  AUDIO_SET_FILTERS_CHANGED: 'AUDIO_FILTERS_CHANGED',
  AUDIO_SET_PENDING_PAST_VOLUME: 'AUDIO_SET_PENDING_PAST_VOLUME',
  AUDIO_SET_PAST_VOLUME: 'AUDIO_SET_PAST_VOLUME',
};

const audioPlay = createAction(TYPES.AUDIO_PLAY);

export function playAudio() {
  return (dispatch) => {
    dispatch(audioPlay());
  };
}

const audioSetPendingPlay = createAction(TYPES.AUDIO_SET_PENDING_PLAY);

export function setPendingPlayAudio(
  audioSourceUrl: string,
  currentTime = 0,
  stockItem: StockItem,
  stockItemArtists?: StockItemArtistInterface[],
  stockItemFormats?: StockItemFormat[],
  waveformData?: WaveformDataInterface,
  context?: StockItemContext
) {
  return (dispatch) => {
    dispatch(
      audioSetPendingPlay({
        audioSourceUrl,
        currentTime,
        stockItem,
        stockItemArtists,
        stockItemFormats,
        waveformData,
        context,
      })
    );
  };
}

const audioPause = createAction(TYPES.AUDIO_PAUSE);

export function pauseAudio() {
  return (dispatch) => {
    dispatch(audioPause());
  };
}

const audioSetPendingPause = createAction(TYPES.AUDIO_SET_PENDING_PAUSE);

export function setPendingPauseAudio() {
  return (dispatch) => {
    dispatch(audioSetPendingPause());
  };
}

const audioSetSource = createAction(TYPES.AUDIO_SET_SOURCE);

export function setAudioSource(audioSourceUrl) {
  return (dispatch) => {
    dispatch(audioSetSource({ audioSourceUrl }));
  };
}

const audioSetCurrentTime = createAction(TYPES.AUDIO_SET_CURRENT_TIME);

export function setCurrentTime(currentTime, duration) {
  return (dispatch) => {
    dispatch(audioSetCurrentTime({ currentTime, duration }));
  };
}

const audioSetCurrentVolume = createAction(TYPES.AUDIO_SET_CURRENT_VOLUME);

export function setAudioCurrentVolume(volume) {
  return (dispatch) => {
    dispatch(audioSetCurrentVolume({ volume }));
  };
}

const audioSetPendingCurrentTime = createAction(
  TYPES.AUDIO_SET_PENDING_CURRENT_TIME
);

export function setPendingCurrentTime(currentTime, stockItem) {
  return (dispatch) => {
    dispatch(audioSetPendingCurrentTime({ currentTime, stockItem }));
  };
}

const audioSetPendingCurrentVolume = createAction(
  TYPES.AUDIO_SET_PENDING_CURRENT_VOLUME
);

export function setPendingCurrentVolume(volume) {
  return (dispatch) => {
    dispatch(audioSetPendingCurrentVolume({ volume }));
  };
}

const audioSetPendingPastVolume = createAction(
  TYPES.AUDIO_SET_PENDING_PAST_VOLUME
);

export function setPendingPastVolume(volume) {
  return (dispatch) => {
    dispatch(audioSetPendingPastVolume({ volume }));
  };
}

const audioSetPastVolume = createAction(TYPES.AUDIO_SET_PAST_VOLUME);

export function setAudioPastVolume(volume) {
  return (dispatch) => {
    dispatch(audioSetPastVolume({ volume }));
  };
}

const audioSetPendingSource = createAction(TYPES.AUDIO_SET_PENDING_SOURCE);

export function setPendingSource(audioSourceUrl, stockItem) {
  return (dispatch) => {
    dispatch(audioSetPendingSource({ audioSourceUrl, stockItem }));
  };
}

const audioClearPendingValues = createAction(TYPES.AUDIO_CLEAR_PENDING_VALUES);

export function clearPendingAudioValues() {
  return (dispatch) => {
    dispatch(audioClearPendingValues());
  };
}
const audioSetPendingPlayNext = createAction(TYPES.AUDIO_SET_PENDING_PLAY_NEXT);

export function setPendingPlayNext() {
  return (dispatch) => {
    dispatch(audioSetPendingPlayNext());
  };
}

const audioSetPendingPlayPrev = createAction(TYPES.AUDIO_SET_PENDING_PLAY_PREV);

export function setPendingPlayPrev() {
  return (dispatch) => {
    dispatch(audioSetPendingPlayPrev());
  };
}

export const audioSetFiltersChanged = createAction(
  TYPES.AUDIO_SET_FILTERS_CHANGED
);

export function setAudioFiltersChanged(didChange) {
  return (dispatch) => {
    dispatch(audioSetFiltersChanged(didChange));
  };
}
