import { StockItem } from '../common/types/StockItemTypes';

export interface MediaTypeSearchReducerState {
  activeTab: number;
  tabs: Array<{
    name: string;
    type: string;
    content: StockItem[];
  }>;
  browseText?: string;
  browseUrlArgs?: string;
  shouldInvertControlsVisibility?: boolean;
  isMember?: boolean;
}

class MediaTypeSearchStateDefinition {
  static getInitialState(): MediaTypeSearchReducerState {
    return {
      activeTab: null,
      tabs: [],
    };
  }
}

export default MediaTypeSearchStateDefinition;
