import { TYPES } from './NPSModalLoaderActions';
import NPSModalLoaderStateDefinition from './NPSModalLoaderStateDefinition';

function npsModalLoaderReducer(
  state = NPSModalLoaderStateDefinition.getInitialState(),
  action
) {
  switch (action.type) {
    case TYPES.RATING_SUBMITTED:
      return {
        ...state,
        responsePromise: action.payload,
      };

    default:
      return state;
  }
}

export default npsModalLoaderReducer;
