import { TYPES } from './CancelActions';
import CancelStateDefinition from './CancelStateDefinition';

function cancelContainerReducer(
  state = CancelStateDefinition.getInitialState(),
  action
) {
  switch (action.type) {
    case TYPES.DECLINED_OFFER_PAGE_COUNT:
      return {
        ...state,
        declinedOfferPageNumber: action.payload,
      };
    case TYPES.ACCEPTED_OFFER_PAGE_COUNT:
      return {
        ...state,
        acceptedOfferPageNumber: action.payload,
      };
    case TYPES.UPDATED_WHY_CANCEL_ANSWER_REASON:
      return {
        ...state,
        whyCancelAnswer: action.payload,
      };
    case TYPES.UPDATED_FOLLOW_UP_ANSWER_REASON:
      return {
        ...state,
        whyCancelAnswer: action.payload,
      };
    case TYPES.UPDATED_OTHER_ANSWER_REASON:
      return {
        ...state,
        whyCancelAnswer: action.payload,
      };
    case TYPES.UPDATED_USE_THIRD_SECTION_OTHER:
      return {
        ...state,
        useThirdSectionOther: action.payload,
      };
    case TYPES.UPDATED_WHY_CANCEL:
      return {
        ...state,
        whyCancelAnswer: action.payload.whyCancelAnswer,
        followUpAnswer: action.payload.followUpAnswer,
        thirdSectionOther: action.payload.thirdSectionOther,
        useThirdSectionOther: action.payload.useThirdSectionOther,
      };
    case TYPES.UPDATED_SECONDARY_QUESTION:
      return {
        ...state,
        followUpAnswer: action.payload.followUpAnswer,
        thirdSectionOther: action.payload.thirdSectionOther,
        useThirdSectionOther: action.payload.useThirdSectionOther,
      };

    default:
      return state;
  }
}

export default cancelContainerReducer;
