export const TYPES = {
  DECLINED_OFFER_PAGE_COUNT: 'DECLINED_OFFER_PAGE_COUNT',
  ACCEPTED_OFFER_PAGE_COUNT: 'ACCEPTED_OFFER_PAGE_COUNT',
  UPDATED_WHY_CANCEL_ANSWER_REASON: 'UPDATED_WHY_CANCEL_ANSWER_REASON',
  UPDATED_FOLLOW_UP_ANSWER_REASON: 'UPDATED_FOLLOW_UP_ANSWER_REASON',
  UPDATED_OTHER_ANSWER_REASON: 'UPDATED_OTHER_ANSWER_REASON',
  UPDATED_USE_THIRD_SECTION_OTHER: 'UPDATED_USE_THIRD_SECTION_OTHER',
  UPDATED_WHY_CANCEL: 'UPDATED_WHY_CANCEL',
  UPDATED_SECONDARY_QUESTION: 'UPDATED_SECONDARY_QUESTION',
};
export const setDeclinedOfferPageCount = (page: number) => ({
  type: TYPES.DECLINED_OFFER_PAGE_COUNT,
  payload: page,
});

export const setAcceptedOfferPageCount = (page: number) => ({
  type: TYPES.ACCEPTED_OFFER_PAGE_COUNT,
  payload: page,
});

export const setWhyCancelAnswer = (reason: string) => ({
  type: TYPES.UPDATED_WHY_CANCEL_ANSWER_REASON,
  payload: reason,
});

export const setFollowUpAnswer = (reason: string) => ({
  type: TYPES.UPDATED_FOLLOW_UP_ANSWER_REASON,
  payload: reason,
});

export const setThirdSectionOther = (reason: string) => ({
  type: TYPES.UPDATED_OTHER_ANSWER_REASON,
  payload: reason,
});

export const setUseThirdSectionOther = (value: boolean) => ({
  type: TYPES.UPDATED_USE_THIRD_SECTION_OTHER,
  payload: value,
});

export const setWhyCancellingQuestion = (
  whyCancelAnswer: string,
  followUpAnswer: string,
  thirdSectionOther: string,
  useThirdSectionOther: boolean
) => ({
  type: TYPES.UPDATED_WHY_CANCEL,
  payload: {
    whyCancelAnswer,
    followUpAnswer,
    thirdSectionOther,
    useThirdSectionOther,
  },
});

export const setDropDownSecondaryQuestion = (
  followUpAnswer: string,
  thirdSectionOther: string,
  useThirdSectionOther: boolean
) => ({
  type: TYPES.UPDATED_SECONDARY_QUESTION,
  payload: {
    followUpAnswer,
    thirdSectionOther,
    useThirdSectionOther,
  },
});
