import merge from 'lodash/merge';
import * as queryString from 'query-string';

import AbstractAPI from '../../common/AbstractAPI';
import SiteConstants, {
  SiteEnum,
} from '../../common/SiteConstants/SiteConstants';
import { buildRequestUrl } from '../../common/utils';
import { AnySearchFilterOptions } from './containers/MenuContainerInterfaces';
import {
  ALL_AUDIO_CONTENT_TYPE,
  ALL_IMAGES_CONTENT_TYPE,
  ALL_VIDEOS_CONTENT_TYPE,
} from './entities/SearchFilterOptions';

class SearchAPI extends AbstractAPI {
  static search(selectedSearchFilterOptions: AnySearchFilterOptions) {
    const parameters = selectedSearchFilterOptions.buildSearchApiParameters();

    // Remove "empty" parameters
    for (const key in parameters) {
      if (
        parameters[key] &&
        (parameters[key].length <= 0 ||
          this.containsAllParameter(parameters[key]) ||
          key === 'templateType')
      ) {
        delete parameters[key];
      }
    }

    const url = buildRequestUrl(
      this.getBaseUrl() + SiteConstants.buildInstance().getSearchApiBasePath(),
      parameters
    );

    return this.request(url, this.buildFetchOptions());
  }

  /**
   * @param {SiteConstants.VIDEOBLOCKS | SiteConstants.AUDIOBLOCKS | SiteConstants.GRAPHICSTOCK } site
   * @param {object} searchFilters result of (Video | Audio | Image)SearchOptions.buildSearchApiParameters()
   */
  static searchBuilder(site: SiteEnum, searchFilters: object) {
    const queryParams = Object.entries(searchFilters).reduce(
      (params, [key, value]) => {
        if (value || this.containsAllParameter(value)) {
          params[key] = value;
        }

        return params;
      },
      {}
    );

    const url = buildRequestUrl(
      this.getBaseUrl() +
        SiteConstants.buildInstance(site).getSearchApiBasePath(),
      queryParams
    );

    return this.request(url, this.buildFetchOptions());
  }

  static containsAllParameter(parameter) {
    return (
      parameter === 'all' ||
      parameter === ALL_VIDEOS_CONTENT_TYPE ||
      parameter === ALL_AUDIO_CONTENT_TYPE ||
      parameter === ALL_IMAGES_CONTENT_TYPE
    );
  }

  static appendSearchLogParameters(
    url,
    searchLogParameters?: object,
    extraParameters = {}
  ) {
    const urlParts = url.split('?');
    let baseUrl,
      query = null;

    if (urlParts.length > 0) {
      baseUrl = urlParts[0];
    }

    if (urlParts.length > 1) {
      query = urlParts[1];
    }

    let parameters = query ? queryString.parse(query) : {};

    if (searchLogParameters) {
      parameters = merge(parameters, searchLogParameters);
    }

    return buildRequestUrl(baseUrl, merge(parameters, extraParameters));
  }
}

export default SearchAPI;
