import update from 'immutability-helper';

import { TYPES } from './UiActions';
import UiStateDefinition from './UiStateDefinition';

function UiReducer(state = UiStateDefinition.getInitialState(), action) {
  switch (action.type) {
    case TYPES.SET_MENU_IS_OPEN:
      return update(state, {
        nav: {
          menu: {
            isOpen: { $set: action.payload },
          },
        },
      });
    default:
      return state;
  }
}

export default UiReducer;
