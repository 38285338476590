import { SiteEnum } from '../../../common/SiteConstants/SiteConstants';
import { getCurrentSite } from '../../../common/utils';
import AudioSearchOptions from './AudioSearchOptions';
import ImageSearchOptions from './ImageSearchOptions';
import VideoSearchOptions from './VideoSearchOptions';

class SearchOptions {
  static getDefaultSearchOptions() {
    const currentSite = getCurrentSite();

    switch (currentSite) {
      case SiteEnum.Graphicstock:
        return new ImageSearchOptions();
      case SiteEnum.Storyblocks:
      case SiteEnum.Videoblocks:
        return new VideoSearchOptions();

      case SiteEnum.Audioblocks:
        return new AudioSearchOptions();

      default:
        return null;
    }
  }
}

export default SearchOptions;
