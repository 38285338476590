export type CancelReducerState = {
  declinedOfferPageNumber: number;
  acceptedOfferPageNumber: number;
  whyCancelAnswer: string;
  followUpAnswer: string;
  thirdSectionOther: string;
  useThirdSectionOther: boolean;
};

class CancelStateDefinition {
  static getInitialState(): CancelReducerState {
    return {
      declinedOfferPageNumber: 0,
      acceptedOfferPageNumber: 0,
      whyCancelAnswer: '',
      followUpAnswer: '',
      thirdSectionOther: '',
      useThirdSectionOther: false,
    };
  }
}

export default CancelStateDefinition;
