import { FoldersItem } from '../MemberBin/FolderStateDefinition';

export interface Folder {
  dateUpdated: string;
  description?: string;
  id: number;
  isOwnedByMe: boolean;
  isShared: boolean;
  isUpdating?: boolean;
  itemCount: number;
  name: string;
  ownerName?: string;
  thumbnailUrl: string;
  uniqueId: string;
  contentIds: number[];
  stockItemIds: number[];
  hasUserSubscription?: boolean;
}

export interface FolderItemWithUIState extends FoldersItem {
  checked?: boolean;
  shouldShowSelectCheckbox?: boolean;
}

export interface RemovedStockItem {
  contentClass: string;
  stockItemId: number;
}

export interface Features {
  redirectToSignUpLink: string;
  isEnterpriseMember: boolean;
  downloadPermissions: Record<string, boolean>;
  isInVisitorVideoThumbnailNewStylingExperiment?: boolean;
}

export interface FolderFeedbackContent {
  firstName: string;
  lastName: string;
  emailAddress: string;
  comments: string;
}

export const TYPES = {
  FOLDER_FEEDBACK_SUBMIT_STARTED: 'FOLDER_FEEDBACK_SUBMIT_STARTED',
  FOLDER_FEEDBACK_SUBMIT_FAILED: 'FOLDER_FEEDBACK_SUBMIT_FAILED',
  FOLDER_FEEDBACK_SUBMIT_COMPLETED: 'FOLDER_FEEDBACK_SUBMIT_COMPLETED',
};
