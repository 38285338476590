import { TYPES } from './SignUpActions';
import SignUpStateDefinition from './SignUpStateDefinition';

function signUpContainerReducer(
  state = SignUpStateDefinition.getInitialState(),
  action
) {
  switch (action.type) {
    case TYPES.CHANGED_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case TYPES.TOGGLED_PLAN_TYPE:
      return {
        ...state,
        isAnnualSelected: action.payload,
      };

    case TYPES.UPDATED_SELECTED_PLAN_UNIQUE_ID:
      return {
        ...state,
        selectedPlanUniqueId: action.payload,
      };

    case TYPES.TOGGLED_IS_FETCHING_ORDER_UPDATE:
      return {
        ...state,
        isFetchingOrderUpdate: action.payload,
      };

    case TYPES.TOGGLED_ORDER_SUMMARY:
      return {
        ...state,
        orderSummary: action.payload,
      };

    case TYPES.TOGGLED_IS_PROCESSING_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isProcessingSubscriptionRequest: action.payload,
      };

    case TYPES.TOGGLED_NEEDS_FINAL_CONFIRMATION:
      return {
        ...state,
        needsFinalConfirmation: action.payload,
      };

    case TYPES.UPDATED_PAYMENT_TOKEN:
      return {
        ...state,
        paymentToken: action.payload,
      };

    case TYPES.UPDATED_PAYMENT_METHOD_CONFIGURATION:
      return {
        ...state,
        paymentMethodConfiguration: action.payload,
      };

    case TYPES.TOGGLED_IS_EXISTING_USER:
      return {
        ...state,
        isExistingUser: action.payload,
      };

    case TYPES.TOGGLED_IS_SIGN_UP_ONLY:
      return {
        ...state,
        isSignUpOnly: action.value,
      };

    default:
      return state;
  }
}

export default signUpContainerReducer;
