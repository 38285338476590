import { PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';

import FolderFeedbackStateDefinition, {
  FolderFeedbackState,
} from '../FolderFeedbackStateDefinition';
import { TYPES } from '../types';

export default function folderFeedbackReducer(
  state: FolderFeedbackState = FolderFeedbackStateDefinition.getInitialState(),
  action: PayloadAction<any>
): FolderFeedbackState {
  switch (action.type) {
    case TYPES.FOLDER_FEEDBACK_SUBMIT_STARTED:
      return update(state, {
        message: { $set: null },
        isSubmitting: { $set: true },
      });

    case TYPES.FOLDER_FEEDBACK_SUBMIT_FAILED:
      return update(state, {
        message: {
          $set: {
            type: 'error',
            text: action.payload,
          },
        },
        isSubmitting: { $set: false },
      });

    case TYPES.FOLDER_FEEDBACK_SUBMIT_COMPLETED:
      return update(state, {
        isSubmitting: { $set: false },
        message: {
          $set: {
            type: 'success',
            text: 'Your feedback was sent successfully.',
          },
        },
      });

    default:
      return state;
  }
}
