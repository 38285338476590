export const TYPES = {
  SET_MENU_IS_OPEN: 'SET_MENU_IS_OPEN',
  CHANGE_CRUMBS: 'CHANGE_CRUMBS',
};

export const updateCrumbs = (newCrumbs) => ({
  type: TYPES.CHANGE_CRUMBS,
  payload: newCrumbs,
});

export const setMenuIsOpen = (open: boolean) => ({
  type: TYPES.SET_MENU_IS_OPEN,
  payload: open,
});
