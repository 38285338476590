import { Middleware } from 'redux';

import Logger from '../../../common/Logger';
import { FoldersState } from '../FoldersStateDefinition';
import { TYPES } from '../FoldersTypes';

export const FOLDERS_STORAGE_KEY = 'folders';

export type FetchFoldersResponse = Pick<
  FoldersState,
  'bins' | 'hasEnterpriseSupport' | 'isInSharedFoldersBeta' | 'organizationName'
>;

export const persistFoldersMiddleware: Middleware =
  (store) => (next) => (action) => {
    const returnValue = next(action);

    if (!window?.localStorage) return returnValue;

    if (
      [
        TYPES.FETCH_ALL_FOLDERS_COMPLETED,
        TYPES.ADD_NEW_FOLDER_WITH_STOCK_ITEM_COMPLETED,
        TYPES.ADD_STOCK_ITEM_TO_FOLDER_COMPLETED,
        TYPES.CLONE_FOLDER_COMPLETED,
        TYPES.DELETE_FOLDER_COMPLETED,
        TYPES.UPDATE_FOLDER_ACCESS_CONTROL_COMPLETED,
        TYPES.UPDATE_FOLDER_NAME_COMPLETED,
      ].includes(action.type)
    ) {
      const {
        bins,
        hasEnterpriseSupport,
        isInSharedFoldersBeta,
        organizationName,
      } = store.getState().memberBins;
      const resultsToPersist: FetchFoldersResponse = {
        bins,
        hasEnterpriseSupport,
        isInSharedFoldersBeta,
        organizationName,
      };

      try {
        localStorage.setItem(
          FOLDERS_STORAGE_KEY,
          JSON.stringify(resultsToPersist)
        );
      } catch (err) {
        Logger.error(`localStorage Quota Exceeded: ${err}`);
      }
    } else if (
      [
        TYPES.ADD_NEW_FOLDER_WITH_STOCK_ITEM_STARTED,
        TYPES.ADD_STOCK_ITEM_TO_FOLDER_STARTED,
        TYPES.CLONE_FOLDER_STARTED,
        TYPES.DELETE_FOLDER_STARTED,
        TYPES.UPDATE_FOLDER_ACCESS_CONTROL_STARTED,
        TYPES.UPDATE_FOLDER_NAME_STARTED,
      ].includes(action.type)
    ) {
      localStorage.removeItem(FOLDERS_STORAGE_KEY);
    }

    return returnValue;
  };
