import { BaseAvroRecord } from '@videoblocks/events-ts/lib/BaseAvroRecord';
import { Device } from '@videoblocks/events-ts/lib/storyblocks/common/Device';
import { Geo } from '@videoblocks/events-ts/lib/storyblocks/common/Geo';
import { Meta } from '@videoblocks/events-ts/lib/storyblocks/common/Meta';
import { Origin } from '@videoblocks/events-ts/lib/storyblocks/common/OriginEnum';
import { TraceContext } from '@videoblocks/events-ts/lib/storyblocks/common/TraceContext';
import { UrlsInterface } from '@videoblocks/events-ts/lib/storyblocks/common/Urls';
import { User } from '@videoblocks/events-ts/lib/storyblocks/common/User';
import { Browser } from '@videoblocks/events-ts/lib/storyblocks/common/frontend/Browser';
import { Navigation } from '@videoblocks/events-ts/lib/storyblocks/common/frontend/Navigation';
import { NavigationType } from '@videoblocks/events-ts/lib/storyblocks/common/frontend/NavigationTypeEnum';
import { Tab } from '@videoblocks/events-ts/lib/storyblocks/common/frontend/Tab';
import { SearchIdentifiersInterface } from '@videoblocks/events-ts/lib/storyblocks/search/records/SearchIdentifiers';
import { SearchIdentifiers } from '@videoblocks/events-ts/lib/storyblocks/search/records/SearchIdentifiers';
import { EventFactory } from '@videoblocks/events-ts/lib/util/EventFactory';
import Client, {
  Telemetry,
  BrowserTracker,
} from '@videoblocks/kafka-rest-client';

import { ReduxState } from '../AppLoader/types';
import { SearchReducerState } from '../app/Search/SearchTypes';

type Vars = {
  __EVENTS_ORIGIN__: Origin;
  __EVENTS_REST_PROXY_URL__: string;
  __EVENTS_REST_PROXY_ENCODE__: boolean;
  __USER__: object;
  __GEO__: object;
  __PAGE_TRACE_ID__: string;
  __INITIAL_STATE__: ReduxState;
};

declare global {
  interface Window extends Vars {
    events: Events;
  }
}

export class Events {
  backendVars: Partial<Vars> = typeof window === 'object' ? window : {};

  origin = this.backendVars.__EVENTS_ORIGIN__ || ('' as Origin);
  restProxyUrl = this.backendVars.__EVENTS_REST_PROXY_URL__ || '';
  encode = this.backendVars.__EVENTS_REST_PROXY_ENCODE__ || false;
  userInfo = this.backendVars.__USER__ || {};
  geoInfo = this.backendVars.__GEO__ || {};
  pageTraceId = this.backendVars.__PAGE_TRACE_ID__ || '';

  /** @var {BrowserTracker} - Initialize v3 browser tracking */
  browserTracker = new BrowserTracker();

  restProxyClient = new Client(this.restProxyUrl, this.origin, this.encode);

  telemetry: typeof Telemetry;

  searchIdentifiers: SearchIdentifiers;

  constructor() {
    // Initialize Telemetry
    Telemetry.init(this.restProxyClient, this.user(), this.trace());
    this.telemetry = Telemetry;

    // Initialize search tracking via searchIdentifiers.
    this.setSearchIdentifiers();
  }

  /**
   * @param {boolean} isBackForward - From the SPA framework, determine if this is a "back" or "forward" in the browser.
   */
  recordPageLoad(isBackForward: boolean) {
    this.browserTracker.recordPageLoad(isBackForward);
  }

  /**
   * @param {BaseAvroRecord} event
   * @param {string} [key] - Optional key for producing this event (produces keys to the same Kafka partition)
   */
  produce(event: BaseAvroRecord, key?: string) {
    this.restProxyClient.produce(event, key).catch((err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Error producing event.', {
          error: err.message,
          type: event.constructor.name,
          event,
        });
      }
    });
  }

  /**
   * Alias for produce. Keeping because it is being used in a GTM snippet (I think).
   * @param {BaseAvroRecord} event
   * @param {?string} key
   */
  logV3Event(event: BaseAvroRecord, key?: string) {
    this.produce(event, key);
  }

  /**
   * @returns {User}
   */
  user(): User {
    const user = new User();
    user.visitorCookieId = this.userInfo['visitorCookieId'];
    user.userId = this.userInfo['userId'] || null;
    user.subscriptionId = this.userInfo['subscriptionId'] || null;
    user.organizationId = this.userInfo['organizationId'] || null;
    return user;
  }

  visitorCookieId() {
    return this.userInfo['visitorCookieId'];
  }

  /**
   * @returns {Meta}
   */
  meta(): Meta {
    return EventFactory.meta(this.origin, this.trace());
  }

  /**
   * @returns {Device}
   */
  device(): Device {
    const navigator = window.navigator || ({} as Navigator);

    const device = new Device();

    device.browser = new Browser();
    device.browser.userAgent = navigator.userAgent;
    device.browser.language = navigator.language;
    device.browser.platform = navigator.platform;

    const browserTrackInfo = this.browserTracker.getPageInfo();
    device.tab = new Tab();
    device.tab.tabId = browserTrackInfo.tabId;
    device.tab.tabCount = browserTrackInfo.tabCount;
    device.tab.isNewTab = browserTrackInfo.newTab === 'New';

    device.navigation = new Navigation();
    // Not a fan of this either.
    device.navigation.navigationType =
      NavigationType[`TYPE_${browserTrackInfo.navigationType}`];
    device.navigation.referrerUrl = window.location.href;

    return device;
  }

  /**
   * @param {SearchIdentifiers} [searchIdentifiers] - If undefined, set from react initial state.
   */
  setSearchIdentifiers(searchIdentifiers?: SearchIdentifiers) {
    if (!searchIdentifiers) {
      const initialSearchState =
        window.__INITIAL_STATE__?.app?.search || ({} as SearchReducerState);

      searchIdentifiers = new SearchIdentifiers();
      searchIdentifiers.searchId = initialSearchState.sslid || '';
      searchIdentifiers.searchPageId = initialSearchState.sguid || '';
    }

    this.searchIdentifiers = searchIdentifiers;
  }

  getSearchIdentifiers(): SearchIdentifiersInterface {
    return this.searchIdentifiers;
  }

  urls(): UrlsInterface {
    return {
      currentUrl: window.location.href,
      referrerUrl: null,
    };
  }

  geo() {
    if (this.geoInfo) {
      const geo = new Geo();
      geo.country = this.geoInfo['country'] || '';
      geo.continent = this.geoInfo['continent'] || '';
      geo.countryCode = this.geoInfo['countryCode'] || '';
      return geo;
    }
  }

  trace() {
    if (this.pageTraceId) {
      const trace = new TraceContext();
      trace.traceId = '';
      trace.spanId = '';
      trace.parentId = '';
      trace.pageTraceId = this.pageTraceId || '';
      return trace;
    }
  }
}

const instance = new Events();
export default instance;
window.events = instance;
