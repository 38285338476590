import { STORYBOARD_STORAGE_KEY } from './middleware/persistStoryboardMiddleware';
import { StoryboardResult } from './types';

export interface StoryboardState {
  currentPayloadHash: string;
  results: Record<string, StoryboardResult & { accessTimestamp?: number }>;
}

const loadLocalStoryboards = (): Pick<StoryboardState, 'results'> => {
  try {
    return {
      results: JSON.parse(sessionStorage.getItem(STORYBOARD_STORAGE_KEY)) || {},
    };
  } catch (e) {
    return { results: {} };
  }
};

export default class StoryboardStateDefinition {
  static getInitialState(): StoryboardState {
    return { currentPayloadHash: '', results: {}, ...loadLocalStoryboards() };
  }
}
