export const legacyContentTypes = [
  {
    label: 'All',
    value: 'all-audio',
  },
  {
    label: 'Music',
    value: 'music',
  },
  {
    label: 'Sound Effects',
    value: 'sfx',
  },
];

export const musicInstruments = [
  {
    label: 'Brass',
    value: '41',
    categoryId: 41,
    urlId: 'brass',
  },
  {
    label: 'Guitar',
    value: '40',
    categoryId: 40,
    urlId: 'guitar-instrument',
  },
  {
    label: 'Orchestra',
    value: '39',
    categoryId: 39,
    urlId: 'orchestra',
  },
  {
    label: 'Percussion',
    value: '42',
    categoryId: 42,
    urlId: 'percussion-instrument',
  },
  {
    label: 'Piano',
    value: '45',
    categoryId: 45,
    urlId: 'piano',
  },
  {
    label: 'Synth',
    value: '119',
    categoryId: 119,
    urlId: 'synth',
  },
  {
    label: 'Woodwind',
    value: '44',
    categoryId: 44,
    urlId: 'woodwind',
  },
];

export const moods = [
  {
    label: 'Angry',
    value: '1',
    categoryId: 1,
    urlId: 'angry',
  },
  {
    label: 'Dark',
    value: '125',
    categoryId: 125,
    urlId: 'dark',
  },
  {
    label: 'Epic',
    value: '124',
    categoryId: 124,
    urlId: 'epic',
  },
  {
    label: 'Happy',
    value: '7',
    categoryId: 7,
    urlId: 'happy',
  },
  {
    label: 'Inspiring',
    value: '4',
    categoryId: 4,
    urlId: 'inspiring',
  },
  {
    label: 'Love',
    value: '8',
    categoryId: 8,
    urlId: 'love',
  },
  {
    label: 'Playful',
    value: '3',
    categoryId: 3,
    urlId: 'playful',
  },
  {
    label: 'Relaxing',
    value: '6',
    categoryId: 6,
    urlId: 'relaxing',
  },
  {
    label: 'Sad',
    value: '5',
    categoryId: 5,
    urlId: 'sad',
  },
  {
    label: 'Serious',
    value: '2',
    categoryId: 2,
    urlId: 'serious',
  },
];

export const genres = [
  {
    label: 'Ambient',
    value: '33',
    categoryId: 33,
    urlId: 'ambient',
  },
  {
    label: 'Blues',
    value: '36',
    categoryId: 36,
    urlId: 'blues',
  },
  {
    label: 'Bumpers & Stingers',
    value: '38',
    categoryId: 38,
    urlId: 'bumpers-and-stingers',
  },
  {
    label: 'Chill Out',
    value: '10',
    categoryId: 10,
    urlId: 'chill-out',
  },
  {
    label: 'Cinematic',
    value: '11',
    categoryId: 11,
    urlId: 'cinematic',
  },
  {
    label: 'Classical',
    value: '32',
    categoryId: 32,
    urlId: 'classical',
  },
  {
    label: 'Corporate',
    value: '22',
    categoryId: 22,
    urlId: 'corporate',
  },
  {
    label: 'Country',
    value: '13',
    categoryId: 13,
    urlId: 'country',
  },
  {
    label: 'Electronic',
    value: '37',
    categoryId: 37,
    urlId: 'electronic',
  },
  {
    label: 'Folk',
    value: '118',
    categoryId: 118,
    urlId: 'folk',
  },
  {
    label: 'Hip Hop',
    value: '20',
    categoryId: 20,
    urlId: 'hip-hop',
  },
  {
    label: 'Holidays & Special Events',
    value: '25',
    categoryId: 25,
    urlId: 'holidays-and-special-events',
  },
  {
    label: 'Horror',
    value: '26',
    categoryId: 26,
    urlId: 'horror',
  },
  {
    label: 'Jazz',
    value: '29',
    categoryId: 29,
    urlId: 'jazz',
  },
  {
    label: 'Kids & Family',
    value: '31',
    categoryId: 31,
    urlId: 'kids-and-family',
  },
  {
    label: 'Pop',
    value: '15',
    categoryId: 15,
    urlId: 'pop',
  },
  {
    label: 'R&B',
    value: '116',
    categoryId: 116,
    urlId: 'rhythm-and-blues',
  },
  {
    label: 'Religious',
    value: '34',
    categoryId: 34,
    urlId: 'religious',
  },
  {
    label: 'Rock',
    value: '9',
    categoryId: 9,
    urlId: 'rock',
  },
  {
    label: 'World',
    value: '21',
    categoryId: 21,
    urlId: 'world',
  },
];

export const vocalType = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Vocals/Lyrical',
    value: true,
  },
  {
    label: 'No Vocals/Instrumental',
    value: false,
  },
];

export const vocalTypeRenamed = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Vocals / Lyrical',
    value: true,
  },
  {
    label: 'No Vocals / Instrumental',
    value: false,
  },
];

export const sfxCategories = [
  {
    label: 'Alarms',
    value: '46',
    categoryId: 46,
    urlId: 'alarms',
  },
  {
    label: 'Ambience',
    value: '47',
    categoryId: 47,
    urlId: 'ambient',
  },
  {
    label: 'Animals',
    value: '48',
    categoryId: 48,
    urlId: 'animals',
  },
  {
    label: 'Applause',
    value: '92',
    categoryId: 92,
    urlId: 'applause',
  },
  {
    label: 'Balloon',
    value: '105',
    categoryId: 105,
    urlId: 'balloon',
  },
  {
    label: 'Bangs',
    value: '49',
    categoryId: 49,
    urlId: 'bangs',
  },
  {
    label: 'Bathroom',
    value: '50',
    categoryId: 50,
    urlId: 'bathroom',
  },
  {
    label: 'Bells',
    value: '51',
    categoryId: 51,
    urlId: 'bells',
  },
  {
    label: 'Birds',
    value: '52',
    categoryId: 52,
    urlId: 'birds',
  },
  {
    label: 'Boats',
    value: '53',
    categoryId: 53,
    urlId: 'boats',
  },
  {
    label: 'Breaking',
    value: '54',
    categoryId: 54,
    urlId: 'breaking',
  },
  {
    label: 'Buzzers',
    value: '55',
    categoryId: 55,
    urlId: 'buzzers',
  },
  {
    label: 'Cartoon',
    value: '57',
    categoryId: 57,
    urlId: 'cartoon',
  },
  {
    label: 'Casino',
    value: '58',
    categoryId: 58,
    urlId: 'casino',
  },
  {
    label: 'City',
    value: '91',
    categoryId: 91,
    urlId: 'city',
  },
  {
    label: 'Clicks',
    value: '59',
    categoryId: 59,
    urlId: 'clicks',
  },
  {
    label: 'Clocks',
    value: '89',
    categoryId: 89,
    urlId: 'clocks',
  },
  {
    label: 'Computer',
    value: '96',
    categoryId: 96,
    urlId: 'computer',
  },
  {
    label: 'Crowds',
    value: '60',
    categoryId: 60,
    urlId: 'crowds',
  },
  {
    label: 'Dogs',
    value: '61',
    categoryId: 61,
    urlId: 'dogs',
  },
  {
    label: 'Doors',
    value: '62',
    categoryId: 62,
    urlId: 'doors',
  },
  {
    label: 'Effects',
    value: '63',
    categoryId: 63,
    urlId: 'effects',
  },
  {
    label: 'Electronics',
    value: '106',
    categoryId: 106,
    urlId: 'electronics',
  },
  {
    label: 'Emergency',
    value: '102',
    categoryId: 102,
    urlId: 'emergency',
  },
  {
    label: 'Explosion',
    value: '98',
    categoryId: 98,
    urlId: 'explosion',
  },
  {
    label: 'Falling',
    value: '64',
    categoryId: 64,
    urlId: 'falling',
  },
  {
    label: 'Festive',
    value: '65',
    categoryId: 65,
    urlId: 'festive',
  },
  {
    label: 'Fight',
    value: '100',
    categoryId: 100,
    urlId: 'fight',
  },
  {
    label: 'Fire',
    value: '66',
    categoryId: 66,
    urlId: 'fire',
  },
  {
    label: 'Flight',
    value: '67',
    categoryId: 67,
    urlId: 'flight',
  },
  {
    label: 'Foley',
    value: '107',
    categoryId: 107,
    urlId: 'foley',
  },
  {
    label: 'Footsteps',
    value: '68',
    categoryId: 68,
    urlId: 'footsteps',
  },
  {
    label: 'Friction',
    value: '69',
    categoryId: 69,
    urlId: 'friction',
  },
  {
    label: 'Furniture',
    value: '95',
    categoryId: 95,
    urlId: 'furniture',
  },
  {
    label: 'Glass',
    value: '108',
    categoryId: 108,
    urlId: 'glass',
  },
  {
    label: 'Horror',
    value: '94',
    categoryId: 94,
    urlId: 'horror',
  },
  {
    label: 'House',
    value: '71',
    categoryId: 71,
    urlId: 'house',
  },
  {
    label: 'Impact',
    value: '103',
    categoryId: 103,
    urlId: 'impact',
  },
  {
    label: 'Industrial',
    value: '72',
    categoryId: 72,
    urlId: 'industrial',
  },
  {
    label: 'Insects',
    value: '90',
    categoryId: 90,
    urlId: 'insects',
  },
  {
    label: 'Kitchen',
    value: '73',
    categoryId: 73,
    urlId: 'kitchen',
  },
  {
    label: 'Magic',
    value: '99',
    categoryId: 99,
    urlId: 'magic',
  },
  {
    label: 'Misc',
    value: '74',
    categoryId: 74,
    urlId: 'misc',
  },
  {
    label: 'Money',
    value: '75',
    categoryId: 75,
    urlId: 'money',
  },
  {
    label: 'Nature',
    value: '93',
    categoryId: 93,
    urlId: 'nature',
  },
  {
    label: 'Office',
    value: '76',
    categoryId: 76,
    urlId: 'office',
  },
  {
    label: 'Paper',
    value: '77',
    categoryId: 77,
    urlId: 'paper',
  },
  {
    label: 'People',
    value: '78',
    categoryId: 78,
    urlId: 'people',
  },
  {
    label: 'Phones',
    value: '79',
    categoryId: 79,
    urlId: 'phones',
  },
  {
    label: 'Ripping',
    value: '80',
    categoryId: 80,
    urlId: 'ripping',
  },
  {
    label: 'Scifi',
    value: '81',
    categoryId: 81,
    urlId: 'scifi',
  },
  {
    label: 'Sports',
    value: '104',
    categoryId: 104,
    urlId: 'sports',
  },
  {
    label: 'Squeaks',
    value: '82',
    categoryId: 82,
    urlId: 'squeaks',
  },
  {
    label: 'Tools and Machines',
    value: '83',
    categoryId: 83,
    urlId: 'tools-and-machines',
  },
  {
    label: 'Toys and Games',
    value: '70',
    categoryId: 70,
    urlId: 'toys-and-games',
  },
  {
    label: 'Trains',
    value: '84',
    categoryId: 84,
    urlId: 'trains',
  },
  {
    label: 'Vehicle and Traffic',
    value: '56',
    categoryId: 56,
    urlId: 'vehicle-and-traffic',
  },
  {
    label: 'Water',
    value: '85',
    categoryId: 85,
    urlId: 'water',
  },
  {
    label: 'Weapons',
    value: '86',
    categoryId: 86,
    urlId: 'weapons',
  },
  {
    label: 'Weather',
    value: '88',
    categoryId: 88,
    urlId: 'weather',
  },
  {
    label: 'Whistles',
    value: '87',
    categoryId: 87,
    urlId: 'whistles',
  },
  {
    label: 'Whoosh',
    value: '97',
    categoryId: 97,
    urlId: 'whoosh',
  },
  {
    label: 'Wood',
    value: '101',
    categoryId: 101,
    urlId: 'wood',
  },
];
