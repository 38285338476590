import { WhitelistRequestFormReducerState } from './WhitelistRequestFormTypes';

export default class WhitelistRequestFormStateDefinition {
  static getInitialState(): WhitelistRequestFormReducerState {
    return {
      claimantsList: [],
      formOpen: false,
      waitingOnFormSubmission: false,
      submissionSuccess: false,
      errorMessage: null,
      recentDownloads: [],
    };
  }
}
