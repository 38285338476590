import { createAction } from 'redux-actions';
import { push } from 'redux-first-history';

import { setSearchOptions } from '../../app/Search/actions/SearchActions';
import { buildRequestUrl } from '../../common/utils';
import PortfolioAPI from '../PortfolioAPI';

export const TYPES = {
  FETCH_PORTFOLIO_RESULTS_STARTED: 'FETCH_PORTFOLIO_RESULTS_STARTED',
  FETCH_PORTFOLIO_RESULTS_FAILED: 'FETCH_PORTFOLIO_RESULTS_FAILED',
  FETCH_PORTFOLIO_RESULTS_COMPLETED: 'FETCH_PORTFOLIO_RESULTS_COMPLETED',
  LOAD_MORE_PORTFOLIO_RESULTS_COMPLETED:
    'LOAD_MORE_PORTFOLIO_RESULTS_COMPLETED',
};

export const fetchPortfolioResultsStarted = createAction(
  TYPES.FETCH_PORTFOLIO_RESULTS_STARTED
);
export const fetchPortfolioResultsFailed = createAction(
  TYPES.FETCH_PORTFOLIO_RESULTS_FAILED,
  (error) => error
);
export const fetchPortfolioResultsCompleted = createAction(
  TYPES.FETCH_PORTFOLIO_RESULTS_COMPLETED
);

export const loadMorePortfolioResultsCompleted = createAction(
  TYPES.LOAD_MORE_PORTFOLIO_RESULTS_COMPLETED
);

export function fetchPortfolioResults(
  username,
  searchOptions,
  isCategory = null
) {
  return (dispatch) => {
    dispatch(fetchPortfolioResultsStarted(searchOptions));
    dispatch(setSearchOptions(searchOptions));

    return PortfolioAPI.get(username, searchOptions)
      .then((response) => response.json())
      .then((json) => {
        dispatch(fetchPortfolioResultsCompleted(json));

        const newUrl = buildRequestUrl(window.location.pathname, {
          'srch-term': searchOptions.searchTerm,
          page: searchOptions.page,
          sort: isCategory ? 'categories' : searchOptions.sort,
          'category-id': searchOptions.categories,
        });

        // Update URL for search query *last*
        return dispatch(push(newUrl));
      })
      .catch((e) => dispatch(fetchPortfolioResultsFailed(e)));
  };
}

export function switchToCategoriesTab(searchOptions) {
  return (dispatch) => {
    dispatch(setSearchOptions(searchOptions));

    const newUrl = buildRequestUrl(window.location.pathname, {
      page: searchOptions.page,
      sort: 'categories',
    });

    return dispatch(push(newUrl));
  };
}
