import { createAction } from 'redux-actions';

export const TYPES = {
  CLOSE_FAVORITES_TOOLTIP: 'CLOSE_FAVORITES_TOOLTIP',
  SELECT_ACQUISITION_OPTION: 'SELECT_ACQUISITION_OPTION',
};

export const selectAcquisitionOption = createAction(
  TYPES.SELECT_ACQUISITION_OPTION,
  (format) => format
);
