export type PlanSelectType = {
  /** Ensure these props match ReactPlanSelectCardModel.php */
  isPopular: boolean;
  title: string;
  subTitle: string;
  defaultPlanIndex: number;
  planOptions: PlanOption[];
  fullFeaturesList?: string[];
  isBundlePlan: boolean;
};

export interface PlanOption {
  hasPremierePro: boolean;
  licenseName?: string;
  monthlyPrice?: string;
  currencySymbol: string;
  amountWithoutSymbol: string;
  hasNonZeroFractionalUnits: boolean;
  fractionalUnits?: string;
  currency: string;
  billingMessage: string;
  billingCycle?: string;
  buttonText?: string;
  planHighlightMessage?: string;
  featuresList?: string[];
  downloadMessage?: string;
  exportMessage?: string;
  switchPlanOptions?: {
    renewalMessage?: string;
    formattedBillingPrice?: string;
    formattedTaxedTotal?: string;
    targetPlanInfo?: string;
    targetPlanUniqueId?: string;
    submitUrl?: string;
    stepOneRoute?: string;
  };
  isInitialPlan?: boolean;
  uniqueId: string;
  formattedPrice: string;
  formattedSalesTaxAmount: string;
  taxedFormattedPrice: string;
  monthlyFormattedPrice: string;
  billingIntervalDays: number;
  renewalMessage: string;
  formattedAnnualDiscount?: string;
}

export type PlanSelectCardsReducerState = Array<PlanSelectType>;

class PlanSelectCardsStateDefinition {
  static getInitialState(): PlanSelectCardsReducerState {
    return [];
  }
}

export default PlanSelectCardsStateDefinition;
