import { FoldersItem } from '../MemberBin/FolderStateDefinition';

export interface FolderFeedbackState {
  uniqueId: string;
  ownerName: string;
  folderName: string;
  folderItems: FoldersItem[];
  message: { type: 'error' | 'success'; text: string };
  isSubmitting: boolean;
}

export default class FolderFeedbackStateDefinition {
  static getInitialState(): FolderFeedbackState {
    return {
      uniqueId: '',
      ownerName: '',
      folderName: '',
      folderItems: [],
      message: null,
      isSubmitting: false,
    };
  }
}
