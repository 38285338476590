import { ContentClass } from '../../Shared/enums';

const API_PATHS = {
  [ContentClass.Video]: 'video',
  [ContentClass.Image]: 'images',
  [ContentClass.Audio]: 'audio',
} as const;

function removeHtmlExtension(url: string) {
  if (!url) {
    return '';
  }

  return url.replace(/\.html$/, '');
}

export default async function fetchSimilarItems(
  detailsUrl: string,
  contentClass: ContentClass
) {
  if (!detailsUrl) return;

  try {
    const parts = detailsUrl.split('/');
    const detailsUrlId = parts.pop() || parts.pop();

    const detailsUrlIdSanitized = removeHtmlExtension(detailsUrlId);

    if (API_PATHS[contentClass]) {
      const response = await fetch(
        `/api/${API_PATHS[contentClass]}/stock-items/${detailsUrlIdSanitized}/similar-items`
      );
      if (response.ok) {
        return response.json();
      }
    }
    return null;
  } catch (error) {
    throw new Error('Error fetching similar stock items');
  }
}
