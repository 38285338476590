import AbstractAPI from '../../common/AbstractAPI';
import SiteConstants from '../../common/SiteConstants/SiteConstants';
import { buildRequestUrl } from '../../common/utils';

class CollectionsAPI extends AbstractAPI {
  static get(collectionUrlName, page) {
    const parameters = {
      page,
    };

    const url = buildRequestUrl(
      this.getBaseUrl() +
        SiteConstants.buildInstance().getCollectionsPath() +
        '/' +
        encodeURIComponent(collectionUrlName),
      parameters
    );

    return this.request(url, this.buildFetchOptions());
  }

  /**
   * @param {string[]} routeUris array of route uris for collections to fetch)
   * @param {SiteConstants.VIDEOBLOCKS | SiteConstants.AUDIOBLOCKS | SiteConstants.GRAPHICSTOCK } site
   */
  static getManyByRouteUris(routeUris: string[], site: string) {
    const parameters = {
      routeUris,
    };

    const url = buildRequestUrl(
      this.getBaseUrl() +
        SiteConstants.buildInstance(site).getCollectionsManyPath(),
      parameters
    );

    return this.request(url, this.buildFetchOptions());
  }
}

export default CollectionsAPI;
