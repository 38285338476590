import update from 'immutability-helper';

import { TYPES } from './AuthActions';
import AuthStateDefinition from './AuthStateDefinition';

function authReducer(state = AuthStateDefinition.getInitialState(), action) {
  switch (action.type) {
    case TYPES.UPDATE_DOWNLOADS_REMAINING:
      return update(state, {
        primarySubscription: {
          monthlyDownloadsRemaining: { $set: action.payload },
        },
      });
    case TYPES.LOGGED_IN:
      return update(state, {
        isLoggedIn: { $set: true },
      });

    default:
      return state;
  }
}

export default authReducer;
