import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'redux-first-history';

import { TYPES as PORTFOLIO_TYPES } from '../../../ContributorPortfolio/actions/PortfolioActions';
import { TYPES as FOLDERS_TYPES } from '../../../Member/MemberBins/FoldersTypes';
import SearchStateDefinition from '../SearchStateDefinition';
import { TYPES } from '../SearchTypes';
import { TYPES as COLLECTIONS_TYPES } from '../actions/CollectionsActions';
import { ERROR_CODE_REQUEST_SUPERSEDED } from '../actions/SearchActions';

function restoreHistoryStateForPath(path, state) {
  if (path in state.searchHistory) {
    const historicState = state.searchHistory[path];

    return update(state, {
      stockItems: { $set: historicState.stockItems },
      searchDescription: { $set: historicState.searchDescription },
      searchDescriptionWithoutSearchTerm: {
        $set: historicState.searchDescriptionWithoutSearchTerm,
      },
      hideSearchResults: { $set: historicState.hideSearchResults },
      searchTerm: { $set: historicState.searchTerm },
      selectedSearchFilterOptions: {
        $set: historicState.selectedSearchFilterOptions,
      },
      relatedSearch: { $set: historicState.relatedSearch },
      pageHeading: { $set: historicState.pageHeading },
      drawerIsOpen: { $set: historicState.drawerIsOpen },
      pagination: { $set: historicState.pagination },
    });
  } else {
    // If we do not have it stored in our internal history, we'll have to force a page reload instead
    if (typeof window !== 'undefined') {
      window.location.reload();
    }

    // We have to return something :)
    return state;
  }
}

function storePathInHistory(path, state) {
  return update(state, {
    searchHistory: {
      $merge: {
        [path]: {
          stockItems: state.stockItems,
          searchDescription: state.searchDescription,
          searchDescriptionWithoutSearchTerm:
            state.searchDescriptionWithoutSearchTerm,
          hideSearchResults: state.hideSearchResults,
          searchTerm: state.searchTerm,
          selectedSearchFilterOptions: state.selectedSearchFilterOptions,
          relatedSearch: state.relatedSearch,
          pageHeading: state.pageHeading,
          drawerIsOpen: false, // we want the drawer to go away when navigating between search pages
          pagination: state.pagination,
        },
      },
    },
  });
}

function searchReducer(
  state = SearchStateDefinition.getInitialState(),
  action
) {
  switch (action.type) {
    case TYPES.SET_SEARCH_LOADING:
      return update(state, {
        isLoading: { $set: action.payload },
      });

    case TYPES.FETCH_SEARCH_RESULTS_STARTED:
      return update(state, {
        isLoading: { $set: true },
        isLoadingAdditionalResults: { $set: action.payload.page > 1 },
        lastRequestHash: { $set: action.payload.hash() },
      });

    case TYPES.FETCH_SEARCH_RESULTS_TOTAL_COUNTS_STARTED:
      return update(state, {
        lastRequestHash: { $set: action.payload.hash() },
      });

    case PORTFOLIO_TYPES.FETCH_PORTFOLIO_RESULTS_STARTED:
    case COLLECTIONS_TYPES.FETCH_COLLECTIONS_RESULTS_STARTED:
      return update(state, {
        isLoading: { $set: true },
        isLoadingAdditionalResults: { $set: action.payload.page > 1 },
      });

    case TYPES.FETCH_SEARCH_RESULTS_FAILED:
    case TYPES.FETCH_SEARCH_RESULTS_TOTAL_COUNTS_FAILED:
    case PORTFOLIO_TYPES.FETCH_PORTFOLIO_RESULTS_FAILED:
    case COLLECTIONS_TYPES.FETCH_COLLECTIONS_RESULTS_FAILED:
      // If multiple requests are active, and this one failed because it is not the most recent request
      // we do not need to log it or update the state
      if (
        action.payload.code &&
        action.payload.code === ERROR_CODE_REQUEST_SUPERSEDED
      ) {
        return state;
      }

      return update(state, {
        isLoading: { $set: false },
        isLoadingAdditionalResults: { $set: false },
      });

    case TYPES.FETCH_SEARCH_RESULTS_TOTAL_COUNTS_COMPLETED:
      return update(state, {
        searchResultsTotalCountsForFilters: {
          $set: action.payload,
        },
      });

    case TYPES.FETCH_SEARCH_RESULTS_COMPLETED:
      //TODO: alaughlin - stop doing string replacement when old AB search page is phased out
      document.title =
        action.payload.metaTitle.replace(/<[^>]+>/g, '') || document.title;

      return update(state, {
        canonicalLink: { $set: action.payload.canonicalLink },
        url: { $set: action.payload.url },
        stockItems: { $set: action.payload.stockItems },
        pagination: { $set: action.payload.pagination },
        features: { $set: action.payload.features },
        searchLogParameters: { $set: action.payload.searchLogParameters },
        isLoading: { $set: false },
        isLoadingAdditionalResults: { $set: false },
        selectedStockItem: {
          $set: SearchStateDefinition.getInitialSelectedStockItem(),
        },
        searchDescription: { $set: action.payload.searchDescription },
        searchDescriptionWithoutSearchTerm: {
          $set: action.payload.searchDescriptionWithoutSearchTerm,
        },
        hideSearchResults: { $set: action.payload.hideSearchResults },
        searchTerm: { $set: action.payload.searchTerm },
        relatedSearch: { $set: action.payload.relatedSearch },
        isSideMenuOpened: { $set: action.payload.isSideMenuOpened },
        pageHeading: { $set: action.payload.pageHeading },
        pendingRequestHash: { $set: null },
        statusCode: { $set: action.payload.statusCode },
        suggestionsQuery: { $set: action.payload.suggestionsQuery },
      });

    case TYPES.LOAD_MORE_SEARCH_RESULTS_COMPLETED:
      let stockItems = action.payload.stockItems;
      const pagination = action.payload.pagination;

      if (pagination.currentPageNumber > 1) {
        stockItems = state.stockItems.concat(stockItems);
      }

      document.title =
        action.payload.metaTitle.replace(/<[^>]+>/g, '') || document.title;

      return update(state, {
        canonicalLink: { $set: action.payload.canonicalLink },
        stockItems: { $set: stockItems },
        pagination: { $set: pagination },
        features: { $set: action.payload.features },
        searchLogParameters: { $set: action.payload.searchLogParameters },
        isLoading: { $set: false },
        isLoadingAdditionalResults: { $set: false },
        selectedStockItem: {
          $set: SearchStateDefinition.getInitialSelectedStockItem(),
        },
        searchDescription: { $set: action.payload.searchDescription },
        searchDescriptionWithoutSearchTerm: {
          $set: action.payload.searchDescriptionWithoutSearchTerm,
        },
        hideSearchResults: { $set: action.payload.hideSearchResults },
        searchTerm: { $set: action.payload.searchTerm },
        relatedSearch: { $set: action.payload.relatedSearch },
        isSideMenuOpened: { $set: action.payload.isSideMenuOpened },
        pageHeading: { $set: action.payload.pageHeading },
        pendingRequestHash: { $set: null },
        statusCode: { $set: action.payload.statusCode },
      });

    case COLLECTIONS_TYPES.FETCH_COLLECTIONS_RESULTS_COMPLETED:
    case PORTFOLIO_TYPES.FETCH_PORTFOLIO_RESULTS_COMPLETED:
      return update(state, {
        stockItems: { $set: action.payload.stockItems },
        pagination: { $set: action.payload.pagination },
        isLoading: { $set: false },
        isLoadingAdditionalResults: { $set: false },
        selectedStockItem: {
          $set: SearchStateDefinition.getInitialSelectedStockItem(),
        },
      });

    case COLLECTIONS_TYPES.LOAD_MORE_COLLECTIONS_RESULTS_COMPLETED:
    case PORTFOLIO_TYPES.LOAD_MORE_PORTFOLIO_RESULTS_COMPLETED:
      return update(state, {
        stockItems: { $push: action.payload.stockItems },
        pagination: { $set: action.payload.pagination },
        isLoading: { $set: false },
        isLoadingAdditionalResults: { $set: false },
        selectedStockItem: {
          $set: SearchStateDefinition.getInitialSelectedStockItem(),
        },
      });

    case TYPES.FETCH_COLLAPSED_SET_RESULTS_COMPLETED:
      return update(state, {
        collapsedSetStockItems: { $set: action.payload.data.stockItems },
        drawerContentLoaded: { $set: true },
      });

    case TYPES.SHOW_MORE_DRAWER_ITEMS:
      return update(state, {
        drawerNumberItemsVisible: { $set: action.payload },
      });

    case TYPES.SET_SEARCH_OPTIONS:
      return update(state, {
        selectedSearchFilterOptions: { $set: action.payload },
        shouldUpdateSearchFilters: { $set: false },
      });

    case TYPES.SET_SEARCH_OPTIONS_AND_UPDATE_FILTERS:
      return update(state, {
        selectedSearchFilterOptions: { $set: action.payload },
        shouldUpdateSearchFilters: { $set: true },
      });

    case TYPES.IS_SIDE_MENU_OPEN:
      return update(state, {
        isSideMenuOpened: { $set: action.payload },
      });

    case TYPES.TOGGLE_MOBILE_FILTER_MODAL_OPEN:
      return update(state, {
        mobileFilterModalOpen: { $set: action.payload },
      });

    case TYPES.TOGGLE_MOBILE_SORT_MODAL_OPEN:
      return update(state, {
        mobileSortModalOpen: { $set: action.payload },
      });

    case TYPES.SET_SELECTED_STOCK_ITEM_OPTIONS:
      return update(state, {
        selectedStockItem: { $merge: action.payload },
      });

    case FOLDERS_TYPES.ADD_STOCK_ITEM_TO_FOLDER_COMPLETED:
      const stockItemsWithNewFavorites = state.stockItems;
      Object.keys(action.payload.bins).forEach((binId) => {
        action.payload.bins[binId].stockItemIds.forEach((id) => {
          // NOTE: bin.stockItemIds may reference StockItems that are not
          //       in the current search result set (aka state.stockItems).
          // TODO pretty sure this is wrong, state.stockItems isn't indexed by stock item id
          if (stockItemsWithNewFavorites[id]) {
            stockItemsWithNewFavorites[id].isFavorite = true;
          }
        });
      });
      return {
        ...state,
        stockItems: stockItemsWithNewFavorites,
      };

    case LOCATION_CHANGE: {
      const path = action.payload.pathname + action.payload.search;
      const searchHistoryIsEmpty = Object.keys(state.searchHistory).length <= 0;

      if (action.payload.action === 'PUSH' || searchHistoryIsEmpty) {
        return storePathInHistory(path, state);
      } else if (action.payload.action === 'POP') {
        return restoreHistoryStateForPath(path, state);
      } else {
        return state;
      }
    }

    case TYPES.MORE_LIKE_THIS_DRAWER_EXPAND:
      return {
        ...state,
        drawerIsOpen: true,
        drawerSelectedItemId: action.payload.initialItemId,
        drawerType: action.payload.drawerType,
        drawerContentLoaded: false,
      };

    case TYPES.MORE_LIKE_THIS_DRAWER_COLLAPSE:
      return {
        ...state,
        drawerIsOpen: false,
        drawerSelectedItemId: 0,
        drawerContentLoaded: false,
      };

    case TYPES.SHOW_MAIN_MENU:
      return update(state, {
        menu: {
          isOpen: { $set: true },
        },
      });

    case TYPES.HIDE_MAIN_MENU:
      return update(state, {
        menu: {
          isOpen: { $set: false },
        },
      });

    case TYPES.SHOW_SUB_MENU:
      return update(state, {
        menu: {
          subMenu: {
            isOpen: { $set: true },
            name: { $set: action.payload.name },
            groupName: { $set: action.payload.groupName },
            inputType: { $set: action.payload.inputType },
            allOptions: { $set: action.payload.allOptions },
            onChange: { $set: action.payload.onChange },
          },
        },
      });

    case TYPES.HIDE_SUB_MENU:
      return update(state, {
        menu: {
          subMenu: {
            isOpen: { $set: false },
            name: { $set: '' },
            groupName: { $set: '' },
            inputType: { $set: '' },
            allOptions: { $set: [] },
            onChange: { $set: null },
          },
        },
      });

    case TYPES.CLEAR_SUGGESTIONS_QUERY:
      return update(state, {
        suggestionsQuery: { $set: '' },
      });

    default:
      return state;
  }
}

export default searchReducer;
