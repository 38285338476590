import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import clone from 'lodash/clone';

import {
  ContentCollectionState,
  CrossAssetTab,
} from './ContentCollectionState';

const initialState: ContentCollectionState = {
  selectedTab: CrossAssetTab.video,
  collectionDirectoryLink: '/',
};

const contentCollectionSlice = createSlice({
  name: 'app/contentCollection',
  initialState,
  reducers: {
    changeMobileTab: (state, action: PayloadAction<CrossAssetTab>) => {
      state.selectedTab = action.payload;
    },
  },
});

const { actions, reducer } = contentCollectionSlice;
const { changeMobileTab } = actions;
// @reduxjs/toolkit freezes the "initialState" variable, so it needs to be cloned before re-exporting - Clark - 2022-10-03
const contentCollectionInitialState = clone(initialState);

export { contentCollectionInitialState, reducer, changeMobileTab };
