import { Folder } from '../Folders/types';

export interface FoldersState {
  isLoading: boolean;
  bins: { [key: string]: Folder };
  isInSharedFoldersBeta: boolean;
  hasEnterpriseSupport: boolean;
  organizationName: string;
  featureFlags?: {
    contentAgnosticFoldersFlag?: boolean;
  };
  addBinSuccess?: boolean;
}

class FoldersStateDefinition {
  static getInitialState(): FoldersState {
    return {
      isLoading: true,
      bins: {},
      isInSharedFoldersBeta: false,
      hasEnterpriseSupport: true,
      organizationName: '',
      featureFlags: {
        contentAgnosticFoldersFlag: false,
      },
    };
  }
}

export default FoldersStateDefinition;
