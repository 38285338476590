import { createAction } from 'redux-actions';

import { notify } from '../ReactToastify/containers/DownloadToastifyContainer';

export const TYPES = {
  UPDATE_DOWNLOADS_REMAINING: 'UPDATE_DOWNLOADS_REMAINING',
  LOGGED_IN: 'LOGGED_IN',
};

export const setIsLoggedIn = () => ({
  type: TYPES.LOGGED_IN,
});

export const updateMonthlyDownloadsRemaining = createAction(
  TYPES.UPDATE_DOWNLOADS_REMAINING,
  (monthlyDownloadsRemaining) => monthlyDownloadsRemaining
);

/**
 * Notifies user of download if the download will substract from their monthly download limit
 * This functionality is currently only available to starter monthly bundle plan users.
 * The logic will need to be refactored if this is expanded to more plans (expecially non-bundle plans)
 */
export function notifyUserOfDownload(monthlyDownloadsRemaining) {
  return async (dispatch, getState) => {
    const subscriptionUniqueId = getState().auth.primarySubscription?.uniqueId;
    const isStarterBundle =
      getState().auth.primarySubscription?.isStarterBundle;
    const isGrowth = getState().auth.primarySubscription?.isGrowthBundle;

    const isEssentials = getState().auth.primarySubscription?.isEssentials;
    //The isGrowth condition relates to the mid-tier plan addition in the OMFG-819 AB test
    if (
      (!isStarterBundle && !isGrowth) ||
      !subscriptionUniqueId ||
      isEssentials
    )
      return;

    const currentRemainingDownloads =
      getState().auth.primarySubscription?.monthlyDownloadsRemaining;
    const downloadsRemainingUnchanged =
      currentRemainingDownloads === monthlyDownloadsRemaining;

    if (!downloadsRemainingUnchanged) {
      notify(monthlyDownloadsRemaining, subscriptionUniqueId);
      dispatch(updateMonthlyDownloadsRemaining(monthlyDownloadsRemaining));
    }
  };
}
