import { AudioNavOptions } from '../../common/SiteConstants/AudioSiteConstants';
import siteConstants, {
  SiteEnum,
} from '../../common/SiteConstants/SiteConstants';
import { VideoNavOptions } from '../../common/SiteConstants/VideoSiteConstants';

export const videoOptions = siteConstants
  .buildInstance(SiteEnum.Videoblocks)
  .getStockMediaNavOptions() as VideoNavOptions;

export const audioOptions = siteConstants
  .buildInstance(SiteEnum.Audioblocks)
  .getStockMediaNavOptions() as AudioNavOptions;

export const searchOrigins = {
  FILTERS: 'filters',
  SEARCH_BAR: 'search_bar',
  SORT_BY: 'sort_by',
  TYPE_AHEAD: 'type_ahead',
  STOCK_ITEM_CARD: 'stock_item_card',
  UNKNOWN: '(unknown)',
  HOME: 'home',
  TOP_NAV: 'top_nav',
  DETAILS_PAGE: 'details_page',
  RELATED_SEARCH: 'related-search',
  PAGINATION: 'pagination',
  ARTIST: 'artist',
  APPLIED_FILTERS: 'applied_filters',
  AUTOCORRECT: 'autocorrect',
};
