import { Action, History, Location } from 'history';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router';
import { Store } from 'redux';

import Events from '../Events/Events';
import scheduleLongTasks from '../app/Shared/utils/scheduleLongTasks';
import BrowserHistorySingleton from './BrowserHistorySingleton';
import configureStore from './configureStore';
import { AssetConfigType } from './types';

/**
 * This app loader is executed in the *browser*
 */
class ClientSideAppLoader {
  store: Store;
  history: History;
  mainMountingPoint: HTMLElement;
  hasChangedPage: boolean;

  constructor() {
    this.store = null;
    this.history = null;
    this.mainMountingPoint = null;
    this.hasChangedPage = false;
  }

  async load(reactConfiguration: AssetConfigType) {
    const auxAppsMap = this.getAuxAppsMountingPoints(reactConfiguration);
    const propBasedAppsMap =
      this.getPropsBasedAppsMountingPoints(reactConfiguration);

    this.mainMountingPoint = document.getElementById('main-app');

    const tasks = [
      () => this.initializeState(),
      this.mainMountingPoint
        ? () => this.loadMainApp(reactConfiguration.routes)
        : () => undefined,
      () => this.loadAuxiliaryApps(auxAppsMap, reactConfiguration.auxApps),
      () =>
        this.loadPropsBasedApps(
          propBasedAppsMap,
          reactConfiguration.propBasedApps
        ),
    ];

    await scheduleLongTasks(tasks);
  }

  initializeState() {
    const initialState = __INITIAL_STATE__ || {};
    const browserHistory = BrowserHistorySingleton.getInstance();
    this.store = configureStore(initialState, browserHistory);
    this.history = browserHistory;
  }

  getAuxAppsMountingPoints(
    reactConfiguration: AssetConfigType
  ): Map<string, HTMLElement> {
    const auxApps = new Map<string, HTMLElement>();
    for (const mountingPointId in reactConfiguration.auxApps) {
      if (reactConfiguration.auxApps.hasOwnProperty(mountingPointId)) {
        const mountingPoint = document.getElementById(mountingPointId);
        if (mountingPoint) {
          auxApps.set(mountingPointId, mountingPoint);
        }
      }
    }
    return auxApps;
  }

  getPropsBasedAppsMountingPoints(
    reactConfiguration: AssetConfigType
  ): Map<string, HTMLCollectionOf<Element>> {
    const propBasedApps = new Map<string, HTMLCollectionOf<Element>>();
    for (const mountingPointClass in reactConfiguration.propBasedApps) {
      if (reactConfiguration.propBasedApps.hasOwnProperty(mountingPointClass)) {
        const instancesOfApp =
          document.getElementsByClassName(mountingPointClass);
        propBasedApps.set(mountingPointClass, instancesOfApp);
      }
    }
    return propBasedApps;
  }

  loadMainApp(routes: AssetConfigType['routes']) {
    this.history.listen(this.logPageChangeHandler.bind(this));

    hydrate(
      <Provider store={this.store}>
        <Router history={this.history}>
          <Switch>
            {routes.map(({ path, component }, index) => (
              <Route key={index} exact path={path} component={component} />
            ))}
          </Switch>
        </Router>
      </Provider>,
      this.mainMountingPoint
    );
  }

  logPageChangeHandler(location: Location, action: Action) {
    if (this.hasChangedPage) {
      const isBackForward = action === 'POP';
      Events.recordPageLoad(isBackForward);
    }

    this.hasChangedPage = true;
  }

  async loadAuxiliaryApps(
    mountingPoints: Map<string, HTMLElement>,
    apps: AssetConfigType['auxApps']
  ) {
    await scheduleLongTasks(
      Array.from(mountingPoints.entries()).map(([id, mountingPoint]) => () => {
        const Container = apps[id];
        hydrate(
          <Provider store={this.store}>
            <Container />
          </Provider>,
          mountingPoint
        );
      })
    );
  }

  async loadPropsBasedApps(
    mountingPoints: Map<string, HTMLCollectionOf<Element>>,
    apps: AssetConfigType['propBasedApps']
  ) {
    await scheduleLongTasks(
      Array.from(mountingPoints.entries()).flatMap(
        ([id, htmlElementCollection]) =>
          Array.from(htmlElementCollection).map((mountingPoint) => () => {
            const Container = apps[id];

            hydrate(
              <Provider store={this.store}>
                <Container {...window['containerProps' + mountingPoint.id]} />
              </Provider>,
              mountingPoint
            );
          })
      )
    );
  }
}

export default ClientSideAppLoader;
