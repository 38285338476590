import { createAction } from 'redux-actions';

export const TYPES = {
  ADD_TO_FOLDER_COMPLETED: 'ADD_TO_FOLDER_COMPLETED',
  REMOVE_FROM_FOLDER_COMPLETED: 'REMOVE_FROM_FOLDER_COMPLETED',
};

export const addToFolderCompleted = createAction(TYPES.ADD_TO_FOLDER_COMPLETED);
export const removeFromFolderCompleted = createAction(
  TYPES.REMOVE_FROM_FOLDER_COMPLETED
);
