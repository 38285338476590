import { SearchFilterContentTypes } from '@videoblocks/shared-components';

import { UsageRights } from '../entities/UsageRights';

export enum VideoQualityType {
  HD = 'HD',
  FOUR_K = '4K',
}

export type SelectedSearchFilterOptions<T = AnySearchFilterOptions> = Partial<{
  contentClass: string;
  contentType: SearchFilterContentTypes;
  sort: string;
  sortOrder: string | null;
  page: number;
  libraryId: number;
  loadMore: boolean;
  searchOrigin: string;
  isPagination: boolean;
  searchTerm: string;
  minDuration: number;
  maxDuration: number;
  talentReleased: boolean;
  propertyReleased: boolean;
  update: (data: Partial<T>) => T;
  artists: StockItemArtistInterface[];
  similarTo: number;
  searchSimilarTitle: string;
  templateType: string;
  usageRights: UsageRights;
  resultsPerPage: number;
  hash: () => number;
  buildSearchApiParameters: (params?: Partial<T>) => T;
}>;

// todo: remove/replace with FilterOption
export interface FilterCategories extends FilterOption {}

export interface AudioSelectedSearchFilterOptions
  extends SelectedSearchFilterOptions {
  // Array of category ids.
  musicMoods?: number[];
  // Array of category ids.
  musicGenres?: number[];
  // Array of category ids.
  musicInstruments?: number[];
  vocalType?: string | boolean;
  tempoMax?: number;
  tempoMin?: number;
  // Array of category ids.
  sfxCategories?: number[];
}

export interface ImageSelectedSearchFilterOptions
  extends SelectedSearchFilterOptions {
  orientation?: string;
  color?: string;
  hasTransparency?: boolean;
  // Just a single category id as a string.
  categories?: string;
}

export interface VideoSelectedSearchFilterOptions
  extends SelectedSearchFilterOptions {
  videoQuality?: VideoQualityType;
  frameRates?: string[];
  templateType?: string;
  // Template type id or category id or both separated by a comma.
  categories?: string;
}

export type AnySearchFilterOptions =
  | AudioSelectedSearchFilterOptions
  | ImageSelectedSearchFilterOptions
  | VideoSelectedSearchFilterOptions;

export interface StockItemArtistInterface {
  publisherPro?: boolean;
  artistPro?: boolean;
  publisher?: boolean;
  contributorPortalId?: string;
  alias?: string;
  lastName?: string;
  firstName?: string;
}
