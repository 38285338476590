import { PopUpsReducerState } from './PopUpsTypes';

class PopUpsStateDefinition {
  // matches up with ReactPopUpWidgetViewModel - please keep in sync
  static getInitialState(): PopUpsReducerState {
    return {
      showPopUp: false,
      popUpName: '',
      context: {},
      instanceSpecificInfo: {},
    };
  }
}

export default PopUpsStateDefinition;
