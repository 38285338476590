import update from 'immutability-helper';

import StoryboardStateDefinition, {
  StoryboardState,
} from '../StoryboardStateDefinition';
import {
  MUSIC_RECS_UPDATED,
  STORYBOARD_PARAMETERS_CHOSEN,
  STORYBOARD_REQUEST_COMPLETED,
  STORYBOARD_REQUEST_INITIATED,
  STORYBOARD_SCENE_UPDATED,
  StoryboardAction,
} from '../actions/storyboardActions';

export default function storyboardReducer(
  state: StoryboardState = StoryboardStateDefinition.getInitialState(),
  action: StoryboardAction
): StoryboardState {
  switch (action.type) {
    case STORYBOARD_PARAMETERS_CHOSEN:
      // Replace the existing payload hash to indicate which storyboard we're working on.
      return update(state, {
        currentPayloadHash: { $set: action.payload.hash },
      });

    case STORYBOARD_REQUEST_INITIATED:
      // Create a new entry in the results set showing that the storyboard is loading.
      return update(state, {
        results: {
          [state.results ? '$merge' : '$set']: {
            [action.payload.hash]: {
              isLoading: true,
              error: null,
              scenes: [],
            },
          },
        },
      });

    case STORYBOARD_REQUEST_COMPLETED:
      // Stash the results of the request for a storyboard.
      return update(state, {
        results: {
          $merge: {
            [action.payload.hash]: {
              isLoading: false,
              error: action.payload.response.ok
                ? null
                : action.payload.response.error,
              scenes: action.payload.response.ok
                ? action.payload.response.storyboard?.scenes
                : [],
              accessTimestamp: Date.now(),
            },
          },
        },
      });

    case STORYBOARD_SCENE_UPDATED:
      return update(state, {
        results: {
          [action.payload.hash]: {
            scenes: {
              $splice: [
                // Starting at the targeted scene number, remove one item and insert the new scene in its place.
                [action.payload.scene.sceneNumber, 1, action.payload.scene],
              ],
            },
            accessTimestamp: { $set: Date.now() },
          },
        },
      });

    case MUSIC_RECS_UPDATED:
      return update(state, {
        results: {
          [action.payload.hash]: {
            $merge: {
              musicRecommendations: action.payload.musicRecommendations,
            },
          },
        },
      });

    default:
      return state;
  }
}
