import { Middleware } from 'redux';

import { StoryboardState } from '../StoryboardStateDefinition';
import {
  MUSIC_RECS_UPDATED,
  STORYBOARD_REQUEST_COMPLETED,
  STORYBOARD_SCENE_UPDATED,
} from '../actions/storyboardActions';

export const STORYBOARD_STORAGE_KEY = 'storyboards';

export const persistStoryboardMiddleware: Middleware =
  (store) => (next) => (action) => {
    const returnValue = next(action);

    if (
      [
        STORYBOARD_REQUEST_COMPLETED,
        STORYBOARD_SCENE_UPDATED,
        MUSIC_RECS_UPDATED,
      ].includes(action.type)
    ) {
      // Save the most recent 5 storyboards locally.
      const allResults: StoryboardState['results'] =
        store.getState().storyboard.results;
      // Pull 'em apart, keep ones that are loaded, sort by recently accessed, keep the first 5.
      // Note that all of them stay in the redux store, this filtering is just for what we put in storage.
      const resultsToPersist = Object.fromEntries(
        Object.entries(allResults)
          .filter(([, { error, isLoading }]) => !isLoading && !error)
          .sort(
            ([, { accessTimestamp: a = 0 }], [, { accessTimestamp: b = 0 }]) =>
              b - a
          )
          .slice(0, 5)
      );
      sessionStorage.setItem(
        STORYBOARD_STORAGE_KEY,
        JSON.stringify(resultsToPersist)
      );
    }

    return returnValue;
  };
