import { AudioReducerState } from './AudioTypes';

class AudioStateDefinition {
  static getInitialState(): Partial<AudioReducerState> {
    return {
      isPlaying: false,
      isPaused: false,
      currentTime: 0,
      duration: 0,
      currentVolume: 1,
      pastVolume: 1,
      audioSourceUrl: null,
      filtersChanged: false,
      context: null,
      pending: {
        currentVolume: null,
        pastVolume: null,
        currentTime: null,
        audioSourceUrl: null,
        play: null,
        pause: null,
        stockItem: {},
        playNext: false,
        playPrev: false,
        mute: null,
      },
      meta: {
        stockItem: {},
        stockItemArtists: [],
        stockItemFormats: [],
        waveformData: {},
      },
      globalPlayerActive: false,
    };
  }
}

export default AudioStateDefinition;
