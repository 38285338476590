import { StockItemDetails } from '../../common/types/StockItemTypes';

export interface ContentCollectionState {
  collection?: ContentCollection;
  stockItemDetails?: CollectionStockItemDetails;
  selectedTab: CrossAssetTab;
  collectionDirectoryLink: string;
}

export interface CollectionStockItemDetails {
  audio: StockItemDetails[];
  image: StockItemDetails[];
  video: StockItemDetails[];
}

export interface ContentCollection {
  name: string;
  content: {
    audio: number[];
    image: number[];
    video: number[];
  };
}

export enum CrossAssetTab {
  video = 'video',
  audio = 'audio',
}
