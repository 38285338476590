import AbstractAPI from '../common/AbstractAPI';
import SiteConstants from '../common/SiteConstants/SiteConstants';
import { buildRequestUrl } from '../common/utils';

class PortfolioAPI extends AbstractAPI {
  static get(username, searchOptions) {
    const parameters = {
      'srch-term': searchOptions.searchTerm,
      page: searchOptions.page,
      sort: searchOptions.sort,
      'category-id': searchOptions.categories,
    };

    const url = buildRequestUrl(
      this.getBaseUrl() +
        SiteConstants.buildInstance().getPortfolioBaseApiPath() +
        '/' +
        encodeURIComponent(username),
      parameters
    );

    return this.request(url, this.buildFetchOptions());
  }
}

export default PortfolioAPI;
