import { searchOrigins } from '../../../Shared/constants';
import { AnySearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import { ContentAgnosticQueryParams } from './types';

/**
 * Build an object of query params that apply to video, audio, or images.
 * @param options Selected filter options.
 */
export default function sharedSearchOptionsToQueryParams(
  options: AnySearchFilterOptions
): ContentAgnosticQueryParams {
  const queryParams: ContentAgnosticQueryParams = {};

  if (options.searchOrigin && options.searchOrigin !== searchOrigins.UNKNOWN) {
    queryParams['search-origin'] = options.searchOrigin;
  }

  if (options.sort && options.sort !== 'most_relevant') {
    queryParams.sort = options.sort;
  }

  if (options.sortOrder) {
    queryParams.sortOrder = options.sortOrder;
  }

  if (options.page > 1) {
    queryParams.page = options.page;
  }

  return queryParams;
}
