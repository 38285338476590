import { createMemoryHistory } from 'history';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { Provider } from 'react-redux';
import { StaticRouter } from 'react-router';
import { matchRoutes, renderRoutes } from 'react-router-config';

import configureStore from './configureStore';
import { AssetConfigType } from './types';

// SSR print function, not Window.print
declare function print(message: string): void;

/**
 * This app loader is executed on the *server* with v8js
 */
class ServerSideAppLoader {
  load(reactConfiguration) {
    const enableRouting = __ENABLE_ROUTING__ || false;

    if (enableRouting) {
      this.loadWithRouting(reactConfiguration);
    } else {
      this.loadWithoutRouting(reactConfiguration);
    }
  }

  loadWithoutRouting(reactConfiguration: AssetConfigType) {
    const initialState = __INITIAL_STATE__ || {};
    const containerId = __CONTAINER_ID__ || null;
    // @ts-expect-error configureStore wants history, but we're loading without routing
    const store = configureStore(initialState);

    const Component = reactConfiguration.auxApps[containerId] || null;
    const rootComponent = (
      <Provider store={store}>
        <Component />
      </Provider>
    );

    try {
      print(renderToString(rootComponent));
    } catch (error) {
      print(this.renderError(error, containerId));
    }
  }

  loadWithRouting(reactConfiguration: AssetConfigType) {
    try {
      const initialState = __INITIAL_STATE__ || {};
      const memoryHistory = createMemoryHistory();
      const store = configureStore(initialState, memoryHistory);
      const { routes } = reactConfiguration;

      const matchedRoutes = matchRoutes(routes, __CURRENT_PATH__);
      /**
       * matchedRoutes i.e.:
       * [
       *     {
       *         "route": {
       *             "name": "ImageSearch",
       *             "path": "/images/search*"
       *         },
       *         "match": {
       *             "path": "/images/search*",
       *             "url": "/images/search/dog",
       *             "isExact": true,
       *             "params": {
       *                 "0": "/dog"
       *             }
       *         }
       *     }
       * ]
       */

      if (!matchedRoutes.length) {
        throw new Error('No matched route found!');
      }

      // get RouteConfig (with component) from routes
      const matchedRouteComponent = routes.find(
        (route) => route.path === matchedRoutes[0].match.path
      );
      if (!matchedRouteComponent) {
        throw new Error('No matched route found!');
      }

      const rootComponent = (
        <Provider store={store}>
          <StaticRouter location={__CURRENT_PATH__}>
            {renderRoutes([matchedRouteComponent])}
          </StaticRouter>
        </Provider>
      );

      print(renderToString(rootComponent));
    } catch (e) {
      print(this.renderError(e));
    }
  }

  renderError(error: Error, containerId?: string) {
    const errorMessage = this.isProductionEnvironment()
      ? 'Internal processing error. Please try again later.'
      : error.message;

    return `
            <div class="alert alert-danger">
                <strong>Error!</strong> ${errorMessage}
                <script> 
                    console.log('Debugging for SSR:');
                    console.dir({
                      'errorMessage': ${JSON.stringify(error.message)},
                      'containerId': ${JSON.stringify(containerId)},
                      'stack': ${JSON.stringify(error.stack)}
                    });
                </script>
            </div>
        `;
  }

  isProductionEnvironment() {
    return process.env.NODE_ENV === 'production';
  }
}

export default ServerSideAppLoader;
