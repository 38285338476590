export const enum UsageRights {
  All = 'all',
  Commercial = 'commercial',
  Editorial = 'editorial',
}

export const enum UsageRightsTooltip {
  Commercial = 'Content that is generally available for commercial uses such as advertising, promoting a business or service, merchandising, etc.',
  Editorial = 'Content that is newsworthy or of general public interest and cannot be used commercially to sell, promote, or monetize a business, person or service.',
}
