import { OptimizeExperimentAssignmentEvent } from '@videoblocks/events-ts/lib/storyblocks/optimize/OptimizeExperimentAssignmentEvent';

import events from './Events';

export default class OptimizeExperimentAssignmentEventFactory {
  static create(propertyId, experimentId, variantId) {
    const event = new OptimizeExperimentAssignmentEvent();

    event.meta = events.meta();
    event.user = events.user();

    event.optimizeExperimentId = experimentId;
    event.optimizeVariantId = variantId;
    event.propertyId = propertyId;
    event.ipAddress = window.__USER__['ip'];
    event.urls = events.urls();

    return event;
  }
}
