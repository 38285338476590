import { PageSpeedEvent } from '@videoblocks/events-ts/lib/storyblocks/navigation/PageSpeedEvent';

import events from './Events';

export default class PageSpeedEventFactory {
  static create() {
    // Try to gracefully fallback to legacy timing, particularly for Safari.
    const times =
      performance.getEntriesByType('navigation').pop() || performance.timing;
    if (!times) {
      return null;
    }

    const event = new PageSpeedEvent();
    event.meta = events.meta();
    event.user = events.user();
    event.device = events.device();
    event.urls = events.urls();
    event.geo = events.geo();

    event.dnsLookupMs = Math.round(
      times.domainLookupEnd - times.domainLookupStart
    );
    event.redirectMs = Math.round(times.redirectEnd - times.redirectStart);
    event.tcpConnectMs = Math.round(times.connectEnd - times.connectStart);
    event.timeToFirstByteMs = Math.round(
      times.responseStart - times.requestStart
    );
    event.timeToFirstPaintMs = Math.round(
      times.domContentLoadedEventEnd - times.requestStart
    );
    event.timeToInteractiveMs = Math.round(
      times.domInteractive - times.requestStart
    );
    event.domLoadedMs = Math.round(times.domComplete - times.requestStart);
    event.loadCompleteMs = Math.round(times.loadEventEnd - times.requestStart);

    event.navigationTimingJson = JSON.stringify(times.toJSON());

    return event;
  }
}
