import { DetailsReducerState } from './DetailsTypes';

class DetailsStateDefinition {
  static getInitialState(): DetailsReducerState {
    return {
      canDownload: false,
      makerHooks: { makerPreCreateProjectRoute: '', makerSignupRoute: '' },
      member: {
        canDownloadNonWatermarkedComps: false,
        isEnterpriseMember: false,
      },
      memberDownloadDate: '',
      musicRecommendations: [],
      mvtPages: undefined,
      stockItemArtists: [],
      stockItem: {},
      stockItemFormats: [],
      selectedAcquisitionOption: null,
      features: {},
      isFavorite: false,
      isSavingFavorite: false,
      showFavoritesTooltip: false,
      similarStockItems: [],
      canExportToCloud: false,
      cloudExportSubfolders: [],
      cloudExportStatus: {},
      cloudExportSubfolderStatus: {},
    };
  }
}

export default DetailsStateDefinition;
