import { StockItem } from '../../common/types/StockItemTypes';
import { RemovedStockItem } from '../Folders/types';

export interface FoldersItem extends Record<string, unknown> {
  stockItem: StockItem;
}

export type FolderState = Partial<{
  name: string;
  binUniqueId: string;
  binName: string;
  binItems: FoldersItem[];
  similarContentItems: FoldersItem[];
  fullBinItems: FoldersItem[];
  features: Record<string, unknown>;
  isEnterpriseMember: boolean;
  isInSharedFoldersBeta: boolean;
  isOwnedByMe: boolean;
  isShared: boolean;
  searchKeyword: string;
  loading: boolean;
  memberHasDownloadLimit: boolean;
  sideMenuPoppedOpen: boolean;
  poppedOpenFolderUniqueId: string;
  failed: boolean;
  canUseCsvDownloader: boolean;
  removedStockItems: RemovedStockItem[];
  hasUserSubscription: boolean;
}>;

class FolderStateDefinition {
  static getInitialState(): FolderState {
    return {
      binUniqueId: '',
      binName: '',
      binItems: [],
      similarContentItems: [],
      fullBinItems: [],
      features: {},
      isEnterpriseMember: false,
      isInSharedFoldersBeta: false,
      isOwnedByMe: false,
      isShared: false,
      searchKeyword: '',
      loading: true,
      memberHasDownloadLimit: false,
      sideMenuPoppedOpen: false,
      poppedOpenFolderUniqueId: null,
      failed: false,
    };
  }
}

export default FolderStateDefinition;
