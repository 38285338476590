import { ClickLabel } from '@videoblocks/events-ts/lib/storyblocks/content/ClickLabelEnum';
import { OriginArea } from '@videoblocks/events-ts/lib/storyblocks/content/OriginAreaEnum';
import { StockItemClickEvent } from '@videoblocks/events-ts/lib/storyblocks/content/StockItemClickEvent';

import events from './Events';
import ShallowStockItemFactory from './ShallowStockItemFactory';

export default class StockItemClickEventFactory {
  static download(stockItem, position, clickOriginArea) {
    return StockItemClickEventFactory.create(
      stockItem,
      position,
      ClickLabel.DOWNLOAD,
      clickOriginArea
    );
  }

  static detailsClick(stockItem, position, clickOriginArea) {
    return StockItemClickEventFactory.create(
      stockItem,
      position,
      ClickLabel.DETAILS,
      clickOriginArea
    );
  }

  static relatedVideoClick(stockItem, position, clickOriginArea) {
    return StockItemClickEventFactory.create(
      stockItem,
      position,
      ClickLabel.RELATED_VIDEO,
      clickOriginArea
    );
  }

  /**
   * @param {object} stockItem
   * @param {?number} position - The index of the displayed stock item.
   * @param {ClickLabel} clickLabel - The "type" of click on the stock item, i.e. download, favorite, etc.
   * @param {OriginArea} clickOriginArea - Tracks where a click event comes from, i.e. search, more like this
   * @returns {StockItemClickEvent}
   */
  static create(stockItem, position, clickLabel, clickOriginArea) {
    const event = new StockItemClickEvent();
    event.meta = events.meta();
    event.user = events.user();
    event.urls = events.urls();
    event.device = events.device();
    event.searchIdentifiers = events.getSearchIdentifiers();
    event.stockItem = ShallowStockItemFactory.create(stockItem);
    event.position = position;
    event.label = clickLabel;
    event.clickOriginArea = clickOriginArea;
    return event;
  }
}
