import WhitelistRequestFormStateDefinition from '../Member/WhitelistRequestForm/WhitelistRequestFormStateDefinition';
import { contentCollectionInitialState } from './ContentCollection/ContentCollectionReducer';
import SearchStateDefinition from './Search/SearchStateDefinition';

class AppStateDefinition {
  static getInitialState() {
    return {
      search: SearchStateDefinition.getInitialState(),
      whitelistRequestForm:
        WhitelistRequestFormStateDefinition.getInitialState(),
      contentCollection: contentCollectionInitialState,
    };
  }
}

export default AppStateDefinition;
