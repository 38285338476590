import { AuthReducerState } from './AuthTypes';

class AuthStateDefinition {
  static getInitialState(): Partial<AuthReducerState> {
    return {
      isLoggedIn: false,
      isIpAuth: false,
      user: null,
      subscription: null,
      primarySubscription: null,
      videoSubscription: null,
      audioSubscription: null,
      imageSubscription: null,
      permissions: {},
      featureFlags: {},
    };
  }
}

export default AuthStateDefinition;
