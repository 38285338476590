class BrowseStateDefinition {
  static getInitialState() {
    return {
      stockItems: [],
      isLoggedIn: false,
      isFetchingStockItems: true,
      libraryItems: [],
      genres: [],
      collections: [],
    };
  }
}

export default BrowseStateDefinition;
