import { combineReducers } from 'redux';

import whitelistRequestFormReducer from '../Member/WhitelistRequestForm/reducers/whitelistRequestFormReducer';
import { reducer as contentCollectionReducer } from './ContentCollection/ContentCollectionReducer';
import searchReducer from './Search/reducers/searchReducer';

const appReducer = combineReducers({
  search: searchReducer,
  whitelistRequestForm: whitelistRequestFormReducer,
  contentCollection: contentCollectionReducer,
});

export default appReducer;
