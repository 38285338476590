import update from 'immutability-helper';

import AudioStateDefinition from '../AudioStateDefinition';
import { TYPES } from '../actions/AudioActions';

function audioReducer(state = AudioStateDefinition.getInitialState(), action) {
  switch (action.type) {
    case TYPES.AUDIO_PLAY:
      return update(state, {
        isPlaying: { $set: true },
        isPaused: { $set: false },
        playStartTime: { $set: Date.now() },
      });

    case TYPES.AUDIO_SET_PENDING_PLAY:
      return update(state, {
        filtersChanged: { $set: false },
        context: { $set: action.payload.context },
        pending: {
          play: { $set: true },
          pause: { $set: false },
          audioSourceUrl: { $set: action.payload.audioSourceUrl },
          currentTime: { $set: action.payload.currentTime },
        },
        meta: {
          stockItem: { $set: action.payload.stockItem },
          stockItemArtists: { $set: action.payload.stockItemArtists },
          stockItemFormats: { $set: action.payload.stockItemFormats },
          waveformData: { $set: action.payload.waveformData },
        },
        globalPlayerActive: { $set: true },
      });

    case TYPES.AUDIO_PAUSE:
      return update(state, {
        isPlaying: { $set: false },
        isPaused: { $set: true },
      });

    case TYPES.AUDIO_SET_PENDING_PAUSE:
      return update(state, {
        pending: {
          pause: { $set: true },
          play: { $set: false },
        },
      });

    case TYPES.AUDIO_SET_SOURCE:
      return update(state, {
        audioSourceUrl: { $set: action.payload.audioSourceUrl },
      });

    case TYPES.AUDIO_SET_CURRENT_TIME:
      return update(state, {
        currentTime: { $set: action.payload.currentTime },
        duration: { $set: action.payload.duration },
      });

    case TYPES.AUDIO_SET_CURRENT_VOLUME:
      return update(state, {
        currentVolume: { $set: action.payload.volume },
      });

    case TYPES.AUDIO_SET_PENDING_CURRENT_TIME:
      return update(state, {
        pending: {
          currentTime: { $set: action.payload.currentTime },
        },
        meta: {
          stockItem: { $set: action.payload.stockItem },
        },
      });

    case TYPES.AUDIO_SET_PENDING_CURRENT_VOLUME:
      return update(state, {
        pending: {
          currentVolume: { $set: action.payload.volume },
        },
      });

    case TYPES.AUDIO_SET_PENDING_SOURCE:
      return update(state, {
        pending: {
          audioSourceUrl: { $set: action.payload.audioSourceUrl },
        },
        meta: {
          stockItem: { $set: action.payload.stockItem },
        },
      });

    case TYPES.AUDIO_CLEAR_PENDING_VALUES:
      return update(state, {
        pending: {
          currentVolume: { $set: null },
          pastVolume: { $set: null },
          currentTime: { $set: null },
          audioSourceUrl: { $set: null },
          play: { $set: null },
          pause: { $set: null },
          playNext: { $set: false },
          playPrev: { $set: false },
          mute: { $set: null },
        },
      });

    case TYPES.AUDIO_SET_PENDING_PLAY_NEXT:
      return update(state, {
        pending: {
          playNext: { $set: true },
        },
      });

    case TYPES.AUDIO_SET_PENDING_PLAY_PREV:
      return update(state, {
        pending: {
          playPrev: { $set: true },
        },
      });

    case TYPES.AUDIO_SET_FILTERS_CHANGED:
      return update(state, {
        filtersChanged: { $set: action.payload },
      });

    case TYPES.AUDIO_SET_PENDING_PAST_VOLUME:
      return update(state, {
        pending: {
          pastVolume: { $set: action.payload.volume },
        },
      });

    case TYPES.AUDIO_SET_PAST_VOLUME:
      return update(state, {
        pastVolume: { $set: action.payload.volume },
      });

    default:
      return state;
  }
}

export default audioReducer;
