import { ReduxState } from '../../../AppLoader/types';
import { AbTests } from '../SearchTypes';

export const selectSearchFilterOptions = (state: ReduxState) =>
  state.app.search.selectedSearchFilterOptions;

export const selectSearchAbTests = (state: ReduxState) =>
  state.app.search.features.abTests || {};

export const abTestSelector = (testName: AbTests) => (state: ReduxState) => {
  const abTests = selectSearchAbTests(state);

  return !!abTests[testName];
};

export const selectHideSearchResults = (state: ReduxState): boolean =>
  state.app.search.hideSearchResults;

export const selectSearchMenu = (state: ReduxState) => state.app.search.menu;

export const selectSearchSubMenu = (state: ReduxState) =>
  state.app.search.menu.subMenu;

export const selectSearchResultsTotalCountsForFilters = (state: ReduxState) =>
  state.app.search.searchResultsTotalCountsForFilters;

export const selectSearchFeatures = (state: ReduxState) =>
  state.app.search.features;

export const selectIsEnterpriseMember = (state: ReduxState) => {
  const searchFeatures = selectSearchFeatures(state);

  return searchFeatures.isEnterpriseMember === true;
};

export const selectSearchSignUpLink = (state: ReduxState) => {
  const searchFeatures = selectSearchFeatures(state);

  return searchFeatures.signUpLink;
};

export const selectStockItems = (state: ReduxState) =>
  state.app.search.stockItems;

export const selectSelectedStockItem = (state: ReduxState) =>
  state.app.search.selectedStockItem;

export const selectDrawerIsOpen = (state: ReduxState) =>
  state.app.search.drawerIsOpen;

export const selectDrawerContentLoaded = (state: ReduxState) =>
  state.app.search.drawerContentLoaded;

export const selectDrawerSelectedItemId = (state: ReduxState) =>
  state.app.search.drawerSelectedItemId;

export const selectDrawerType = (state: ReduxState) =>
  state.app.search.drawerType;

export const selectPagination = (state: ReduxState) =>
  state.app.search.pagination;

export const selectUsername = (state: ReduxState) => state.app.search.username;

export const selectSearchIsLoadingAdditionalResults = (state: ReduxState) =>
  state.app.search.isLoadingAdditionalResults;

export const selectTotalItemCountForContributor = (state: ReduxState) =>
  state.app.search.totalItemCountForContributor;

export const selectSearchOptions = (state: ReduxState) =>
  state.app.search.searchOptions;

export const selectCategories = (state: ReduxState) =>
  state.app.search.categories;

export const selectPortfolioInfo = (state: ReduxState) =>
  state.app.search.portfolioInfo;

export const selectSearchIsLoading = (state: ReduxState) =>
  state.app.search.isLoading;

export const selectSearchContext = (state: ReduxState) =>
  state.app.search.context || '';

export const selectCollapsedSetStockItems = (state: ReduxState) =>
  state.app.search.collapsedSetStockItems || [];

export const selectCollectionUrlName = (state: ReduxState) =>
  state.app.search.collectionUrlName;

export const selectShouldShowAddToFavorites = (state: ReduxState) =>
  state.app.search.shouldShowAddToFavorites;

export const selectSearchLogParameters = (state: ReduxState) =>
  state.app.search.searchLogParameters;

export const selectMobileFilterModalOpen = (state: ReduxState) =>
  state.app.search.mobileFilterModalOpen;

export const selectMobileSortModalOpen = (state: ReduxState) =>
  state.app.search.mobileSortModalOpen;

export const selectShouldUpdateSearchFilters = (state: ReduxState) =>
  state.app.search.shouldUpdateSearchFilters;

export const selectIsSearchSideMenuOpened = (state: ReduxState) =>
  state.app.search.isSideMenuOpened;

export const selectSearchDrawerNumberItemsVisible = (state: ReduxState) =>
  state.app.search.drawerNumberItemsVisible;

export const selectSearchStatusCode = (state: ReduxState) =>
  state.app.search.statusCode;

export const selectSearchCountryCode = (state: ReduxState) =>
  state.app.search.countryCode;

export const selectCanonicalLink = (state: ReduxState) =>
  state.app.search.canonicalLink;

export const selectUrl = (state: ReduxState) => state.app.search.url;

export const selectSearchDescription = (state: ReduxState) =>
  state.app.search.searchDescription;

export const selectSearchTerm = (state: ReduxState) =>
  state.app.search.searchTerm;

export const selectSearchDescriptionWithoutSearchTerm = (state: ReduxState) =>
  state.app.search.searchDescriptionWithoutSearchTerm;

export const selectRelatedSearch = (state: ReduxState) =>
  state.app.search.relatedSearch;

export const selectSearchPageHeading = (state: ReduxState) =>
  state.app.search.pageHeading;

export const selectSslid = (state: ReduxState) => state.app.search.sslid;

export const selectContentType = (state: ReduxState) =>
  state.app.search.selectedSearchFilterOptions.contentType;

export const selectSuggestionsQuery = (state: ReduxState) =>
  state.app.search.suggestionsQuery;
