import { SearchReducerState, SelectedStockItem } from './SearchTypes';
import { DRAWER_DEFAULT_ITEM_COUNT } from './actions/SearchActions';
import SearchOptions from './entities/SearchOptions';

class SearchStateDefinition {
  static getInitialState(): Partial<SearchReducerState> {
    return {
      searchResultsTotalCountsForFilters: null,
      mobileFilterModalOpen: false,
      mobileSortModalOpen: false,
      collapsedSetStockItems: [],
      collectionUrlName: null,
      drawerContentLoaded: false,
      drawerIsOpen: false,
      drawerNumberItemsVisible: DRAWER_DEFAULT_ITEM_COUNT,
      drawerSelectedItemId: 0,
      drawerType: null,
      favoritesBinId: null,
      features: {},
      hideSearchResults: false,
      isLoading: false,
      isLoadingAdditionalResults: false,
      isSideMenuOpened: true,
      lastRequestHash: null,
      menu: {
        isOpen: true,
        subMenu: {
          isOpen: false,
          name: '',
          groupName: '',
          inputType: '',
          allOptions: [],
          onChange: null,
        },
      },
      pagination: {}, // Currently used for collections only
      relatedSearch: [],
      searchDescription: '',
      searchDescriptionWithoutSearchTerm: '',
      searchHistory: {},
      searchLogParameters: null,
      searchTerm: '',
      selectedSearchFilterOptions: SearchOptions.getDefaultSearchOptions(),
      selectedStockItem: this.getInitialSelectedStockItem(),
      shouldShowAddToFavorites: false,
      stockItems: [],
      suggestionsQuery: '',
    };
  }

  static getInitialSelectedStockItem(): SelectedStockItem {
    return {
      id: null,
      index: null,
      details: null,
      selectedFormatId: -1,
      formatSelectorVisible: false,
      addSubscription: false,
    };
  }
}

export default SearchStateDefinition;
