export const TYPES = {
  ADD_STOCK_ITEM_TO_FOLDER_STARTED: 'ADD_STOCK_ITEM_TO_FOLDER_STARTED',
  ADD_STOCK_ITEM_TO_FOLDER_FAILED: 'ADD_STOCK_ITEM_TO_FOLDER_FAILED',
  ADD_STOCK_ITEM_TO_FOLDER_COMPLETED: 'ADD_STOCK_ITEM_TO_FOLDER_COMPLETED',

  ADD_NEW_FOLDER_WITH_STOCK_ITEM_STARTED:
    'ADD_NEW_FOLDER_WITH_STOCK_ITEM_STARTED',
  ADD_NEW_FOLDER_WITH_STOCK_ITEM_FAILED:
    'ADD_NEW_FOLDER_WITH_STOCK_ITEM_FAILED',
  ADD_NEW_FOLDER_WITH_STOCK_ITEM_COMPLETED:
    'ADD_NEW_FOLDER_WITH_STOCK_ITEM_COMPLETED',

  FETCH_ALL_FOLDERS_STARTED: 'FETCH_ALL_FOLDERS_STARTED',
  FETCH_ALL_FOLDERS_FAILED: 'FETCH_ALL_FOLDERS_FAILED',
  FETCH_ALL_FOLDERS_COMPLETED: 'FETCH_ALL_FOLDERS_COMPLETED',

  UPDATE_FOLDER_NAME_STARTED: 'UPDATE_FOLDER_NAME_STARTED',
  UPDATE_FOLDER_NAME_FAILED: 'UPDATE_FOLDER_NAME_FAILED',
  UPDATE_FOLDER_NAME_COMPLETED: 'UPDATE_FOLDER_NAME_COMPLETED',

  DELETE_FOLDER_STARTED: 'DELETE_FOLDER_STARTED',
  DELETE_FOLDER_FAILED: 'DELETE_FOLDER_FAILED',
  DELETE_FOLDER_COMPLETED: 'DELETE_FOLDER_COMPLETED',

  CLONE_FOLDER_STARTED: 'CLONE_FOLDER_STARTED',
  CLONE_FOLDER_FAILED: 'CLONE_FOLDER_FAILED',
  CLONE_FOLDER_COMPLETED: 'CLONE_FOLDER_COMPLETED',

  UPDATE_FOLDER_ACCESS_CONTROL_STARTED: 'UPDATE_FOLDER_ACCESS_CONTROL_STARTED',
  UPDATE_FOLDER_ACCESS_CONTROL_FAILED: 'UPDATE_FOLDER_ACCESS_CONTROL_FAILED',
  UPDATE_FOLDER_ACCESS_CONTROL_COMPLETED:
    'UPDATE_FOLDER_ACCESS_CONTROL_COMPLETED',
};
