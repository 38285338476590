import update from 'immutability-helper';

import { TYPES as FOLDERS_TYPES } from '../../Folders/actions/FoldersActions';
import { TYPES as STOCK_ITEM_TYPES } from '../../StockItems/actions/StockItemActions';
import DetailsStateDefinition from '../DetailsStateDefinition';
import { DetailsReducerState } from '../DetailsTypes';
import { TYPES } from '../actions/DetailsActions';

export type DetailsReducerAction<T extends object> = {
  type: string;
  payload: T;
};

type AddRemoveFn = (
  state: DetailsReducerState,
  action: DetailsReducerAction<{ source: string; context: string }>,
  identityFunction: (any) => boolean
) => DetailsReducerState;

const add: AddRemoveFn = (state, action, identityFunction) => {
  // todo: enum for these values
  if (action.payload.context === 'details') {
    if (action.payload.source === 'details') {
      return update(state, {
        isFavorite: { $set: true },
        isSavingFavorite: { $set: false },
        showFavoritesTooltip: { $set: true },
      });
    }
    if (action.payload.source === 'card') {
      return {
        ...state,
        similarStockItems: state.similarStockItems.map((similarStockItem) =>
          identityFunction(similarStockItem.stockItem)
            ? update(similarStockItem, {
                isSavingFavorite: { $set: false },
                isFavorite: { $set: true },
              })
            : similarStockItem
        ),
      };
    }
    return state;
  }
  return state;
};

const remove: AddRemoveFn = (state, action, identityFunction) => {
  if (action.payload.context === 'details') {
    if (action.payload.source === 'details') {
      return update(state, {
        isFavorite: { $set: false },
        isSavingFavorite: { $set: false },
      });
    }
    if (action.payload.source === 'card') {
      return {
        ...state,
        similarStockItems: state.similarStockItems.map((similarStockItem) =>
          identityFunction(similarStockItem.stockItem)
            ? update(similarStockItem, {
                isSavingFavorite: { $set: false },
                isFavorite: { $set: false },
              })
            : similarStockItem
        ),
      };
    }
    return state;
  }
  return state;
};

function detailsReducer(
  state = DetailsStateDefinition.getInitialState(),
  action: DetailsReducerAction<any>
) {
  switch (action.type) {
    case FOLDERS_TYPES.ADD_TO_FOLDER_COMPLETED:
      return add(
        state,
        action,
        (stockItem) => stockItem.contentId === action.payload.contentId
      );

    case FOLDERS_TYPES.REMOVE_FROM_FOLDER_COMPLETED:
      return remove(
        state,
        action,
        (stockItem) => stockItem.contentId === action.payload.contentId
      );

    case TYPES.CLOSE_FAVORITES_TOOLTIP:
      return update(state, {
        showFavoritesTooltip: { $set: false },
      });

    case TYPES.SELECT_ACQUISITION_OPTION:
      return update(state, {
        selectedAcquisitionOption: { $set: action.payload },
      });

    case STOCK_ITEM_TYPES.STOCK_ITEM_EXPORT_REQUESTED:
    case STOCK_ITEM_TYPES.STOCK_ITEM_EXPORT_REQUEST_SUCCEEDED:
    case STOCK_ITEM_TYPES.STOCK_ITEM_EXPORT_REQUEST_FAILED:
      const status = {
        isRequested:
          action.type === STOCK_ITEM_TYPES.STOCK_ITEM_EXPORT_REQUESTED,
        isSucceeded:
          action.type === STOCK_ITEM_TYPES.STOCK_ITEM_EXPORT_REQUEST_SUCCEEDED,
        isFailed:
          action.type === STOCK_ITEM_TYPES.STOCK_ITEM_EXPORT_REQUEST_FAILED,
      };

      if (action.payload && action.payload.exportSubfolder) {
        return update(state, {
          cloudExportSubfolderStatus: {
            [`${action.payload.exportSubfolder}`]: {
              $set: status,
            },
          },
        });
      }
      return update(state, {
        cloudExportStatus: {
          $set: status,
        },
      });

    default:
      return state;
  }
}

export default detailsReducer;
