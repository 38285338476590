import { OrderSummary } from './OrderSummaryDefinition';

export const TYPES = {
  CHANGED_ERROR_MESSAGE: 'CHANGED_ERROR_MESSAGE',
  TOGGLED_PLAN_TYPE: 'TOGGLED_PLAN_TYPE',
  UPDATED_SELECTED_PLAN_UNIQUE_ID: 'UPDATED_SELECTED_PLAN_UNIQUE_ID',
  TOGGLED_IS_FETCHING_ORDER_UPDATE: 'TOGGLED_IS_FETCHING_ORDER_UPDATE',
  TOGGLED_ORDER_SUMMARY: 'TOGGLED_ORDER_SUMMARY',
  TOGGLED_IS_PROCESSING_SUBSCRIPTION_REQUEST:
    'TOGGLED_IS_PROCESSING_SUBSCRIPTION_REQUEST',
  TOGGLED_NEEDS_FINAL_CONFIRMATION: 'TOGGLED_NEEDS_FINAL_CONFIRMATION',
  UPDATED_PAYMENT_TOKEN: 'UPDATED_PAYMENT_TOKEN',
  UPDATED_PAYMENT_METHOD_CONFIGURATION: 'UPDATED_PAYMENT_METHOD_CONFIGURATION',
  TOGGLED_IS_EXISTING_USER: 'TOGGLED_IS_EXISTING_USER',
  TOGGLED_IS_SIGN_UP_ONLY: 'TOGGLED_IS_SIGN_UP_ONLY',
};

export const setErrorMessage = (message: string) => ({
  type: TYPES.CHANGED_ERROR_MESSAGE,
  payload: message,
});

export const setIsAnnualSelected = (value: boolean) => ({
  type: TYPES.TOGGLED_PLAN_TYPE,
  payload: value,
});

export const setSelectedPlanUniqueId = (uniqueId: string) => ({
  type: TYPES.UPDATED_SELECTED_PLAN_UNIQUE_ID,
  payload: uniqueId,
});

export const setIsFetchingOrderUpdate = (value: boolean) => ({
  type: TYPES.TOGGLED_IS_FETCHING_ORDER_UPDATE,
  payload: value,
});

export const setOrderSummary = (data: OrderSummary) => ({
  type: TYPES.TOGGLED_ORDER_SUMMARY,
  payload: data,
});

export const setIsProcessingSubscriptionRequest = (value: boolean) => ({
  type: TYPES.TOGGLED_IS_PROCESSING_SUBSCRIPTION_REQUEST,
  payload: value,
});

export const setNeedsFinalConfirmation = (value) => ({
  type: TYPES.TOGGLED_NEEDS_FINAL_CONFIRMATION,
  payload: value,
});

export const setPaymentToken = (token) => ({
  type: TYPES.UPDATED_PAYMENT_TOKEN,
  payload: token,
});

export const setPaymentMethodConfiguration = (configuration) => ({
  type: TYPES.UPDATED_PAYMENT_METHOD_CONFIGURATION,
  payload: configuration,
});

export const setIsExistingUser = (value: boolean) => ({
  type: TYPES.TOGGLED_IS_EXISTING_USER,
  payload: value,
});

export const setIsSignUpOnly = (value: boolean) => ({
  type: TYPES.TOGGLED_IS_SIGN_UP_ONLY,
  payload: value,
});
