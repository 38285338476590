import { ContentClass } from '../../Shared/enums';
import { ImageSelectedSearchFilterOptions } from '../containers/MenuContainerInterfaces';
import AbstractSearchOptions from './AbstractSearchOptions';
import { ALL_IMAGES_CONTENT_TYPE } from './SearchFilterOptions';

class ImageSearchOptions
  extends AbstractSearchOptions
  implements ImageSelectedSearchFilterOptions
{
  constructor() {
    super({
      ...AbstractSearchOptions.commonDefaultData,
      contentClass: ContentClass.Image,
      contentType: 'all-images',
      hasTransparency: false,
      orientation: 'all',
      color: '',
      talentReleased: false,
      propertyReleased: false,
    });
  }

  update(newData) {
    // Enforce that contentType and orientation can never be empty string or "null"
    if (
      'contentType' in newData &&
      this._isEmptyStringOrNull(newData.contentType)
    ) {
      newData.contentType = ALL_IMAGES_CONTENT_TYPE;
    }

    if (
      'orientation' in newData &&
      this._isEmptyStringOrNull(newData.orientation)
    ) {
      newData.orientation = 'all';
    }

    return super.update(newData);
  }

  _isEmptyStringOrNull(str) {
    return typeof str === 'undefined' || !str || str === '';
  }

  /**
   * Accepts an optional reference object to map to allow static use
   */
  buildSearchApiParameters(searchParams = null) {
    const referenceObject = searchParams || this;

    return {
      color: referenceObject.color,
      hasTransparency: referenceObject.hasTransparency,
      keywords: referenceObject.searchTerm,
      numberResultsPerPage: referenceObject.resultsPerPage,
      page: referenceObject.page,
      'search-origin': referenceObject.searchOrigin,
      'media-type': referenceObject.contentType,
      sort: referenceObject.sort,
      stockMainCategoryIds: referenceObject.categories,
      orientation: referenceObject.orientation,
      is_null_search: 0,
      hasTalentReleased: referenceObject.talentReleased,
      hasPropertyReleased: referenceObject.propertyReleased,
      'collapsed-set-id': referenceObject.collapsedSetId,
      'exclude-stock-item-ids': referenceObject.excludeStockItemIds,
      usage_rights: referenceObject.usageRights,
    };
  }
}

export default ImageSearchOptions;
