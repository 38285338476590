import update from 'immutability-helper';

import PopUpStateDefinition from '../PopUpsStateDefinition';
import { TYPES } from '../actions/PopUpsActions';

function popUpsReducer(state = PopUpStateDefinition.getInitialState(), action) {
  switch (action.type) {
    case TYPES.SHOW_POP_UP:
      return update(state, {
        showPopUp: { $set: true },
        popUpName: { $set: action.popUpName },
        context: { $set: action.context },
      });

    case TYPES.HIDE_POP_UP:
      return update(state, {
        showPopUp: { $set: false },
        popUpName: { $set: action.popUpName },
        context: { $set: {} },
      });

    default:
      return state;
  }
}

export default popUpsReducer;
