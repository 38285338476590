import React from 'react';
import { toast } from 'react-toastify';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import CloseIcon from '@videoblocks/react-icons/Close';
import InformationCircleIcon from '@videoblocks/react-icons/InformationCircle';

import SiteConstants from '../../common/SiteConstants/SiteConstants';

import './DownloadToastifyContainer.less';
import 'react-toastify/dist/ReactToastify.css';

export function notify(remainingDownloads, subscriptionUniqueId, options = {}) {
  const CloseButton = (
    { closeToast } // sb-close.svg
  ) => (
    <button className="w-5 h-5 border-0 outline-none" onClick={closeToast}>
      <CloseIcon className="w-3.5 h-3.5 text-blue-700 fill-current" />
    </button>
  );

  const message = (
    <div className="flex">
      <InformationCircleIcon className="flex-shrink-0 w-5 h-5 text-blue-500 fill-current" />
      <div className="pl-5 pr-4 leading-6 text-blue-700">
        You have {remainingDownloads} downloads left this month. But why limit
        yourself?
        <br />
        <a
          href={`${SiteConstants.getInstance().getUpgradeMyPlanBasePath()}/${subscriptionUniqueId}`}
          className="font-bold text-blue-700 underline cursor-pointer"
          onClick={() => Telemetry.increment('download.toast.userupgrade')}
        >
          Go Unlimited
        </a>
      </div>
    </div>
  );

  const defaultOptions = {
    position: 'top-right',
    autoClose: true,
    closeOnClick: false,
    className:
      'cursor-default bg-blue-100 border-solid border-blue-500 border-t-0 border-r-0 border-b-0 border-l-8 rounded pl-5 pr-8 py-6',
    closeButton: <CloseButton />,
  };

  toast(message, { ...defaultOptions, ...options });
}
