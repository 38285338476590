/**
 * Declarative helper for yielding to the main thread within an async function.
 */
function yieldToMain(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
}

/**
 * Yield to the main thread between execution of tasks.
 * @param tasks
 */
export default async function scheduleLongTasks(
  tasks: Array<() => Promise<void> | void>
): Promise<void> {
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    await task();
    await yieldToMain();
  }
}
