import { TYPES as STOCK_ITEM_ACTION_TYPES } from '../../app/Search/SearchTypes';
import { TYPES as FOLDERS_ACTION_TYPES } from '../MemberBins/FoldersTypes';
import FolderStateDefinition from './FolderStateDefinition';
import { TYPES } from './types';

function reorderStockItemsInArray(
  items,
  moveStockItemIdentifier,
  targetStockItemIdentifier,
  shouldInsertBefore,
  itemIdentity = (item) => item.stockItem.id
) {
  const newItems = items.slice();

  const indexToMove = newItems.findIndex((item) => {
    return moveStockItemIdentifier === itemIdentity(item);
  });
  const stockItemToMove = newItems[indexToMove];
  newItems.splice(indexToMove, 1);

  const targetStockItemIndex = newItems.findIndex((item) => {
    return targetStockItemIdentifier === itemIdentity(item);
  });

  newItems.splice(
    targetStockItemIndex + (shouldInsertBefore ? 0 : 1),
    0,
    stockItemToMove
  );

  return newItems;
}

function folderReducer(
  state = FolderStateDefinition.getInitialState(),
  action
) {
  switch (action.type) {
    case TYPES.FETCH_FOLDER_CONTENT_STARTED:
      return {
        ...state,
        binUniqueId: action.payload,
        poppedOpenFolderUniqueId: action.payload,
      };

    case TYPES.FETCH_FOLDER_CONTENT_COMPLETED:
      const binItems = action.payload.binItems.map((binItem) => {
        return {
          ...binItem,
          shouldShowSelectCheckbox: true,
          checked: false,
        };
      });

      return {
        ...state,
        binName: action.payload.binName,
        binItems: binItems,
        fullBinItems: binItems,
        similarContentItems: action.payload.similarContentItems,
        ownerName: action.payload.ownerName,
        isOwnedByMe: action.payload.isOwnedByMe,
        isShared: action.payload.isShared,
        isInSharedFoldersBeta: action.payload.isInSharedFoldersBeta,
        hasUserSubscription: action.payload.hasUserSubscription,
        loading: false,
        removedStockItems: action.payload.removedStockItems,
        failed: false,
      };

    case TYPES.FETCH_FOLDER_CONTENT_FAILED:
      return {
        ...state,
        failed: true,
        loading: false,
      };

    case TYPES.TOGGLE_SELECT_ALL:
      const modifiedBinItems = state.binItems.map((binItem) => {
        if (
          action.payload.contentClass &&
          action.payload.contentClass !== binItem.stockItem.contentClass
        ) {
          return binItem;
        }

        return {
          ...binItem,
          checked: action.payload.select,
        };
      });

      return {
        ...state,
        binItems: modifiedBinItems,
      };

    case STOCK_ITEM_ACTION_TYPES.STOCK_ITEM_SELECT_TOGGLE:
      const toggledBinItems = state.binItems.map((binItem) => {
        if (binItem.stockItem.id === action.payload.stockItem.id) {
          binItem.checked = !binItem.checked;
        }
        return binItem;
      });

      return {
        ...state,
        binItems: toggledBinItems,
      };

    case TYPES.DELETE_FOLDER_CONTENT_COMPLETED:
      const updatedFolderItems = state.binItems.filter((binItem) => {
        return !action.payload.deletedContentIds.includes(
          binItem.stockItem.contentId
        );
      });

      const fullFolderItems = state.fullBinItems.filter((binItem) => {
        return !action.payload.deletedContentIds.includes(
          binItem.stockItem.contentId
        );
      });

      return {
        ...state,
        binItems: updatedFolderItems,
        fullBinItems: fullFolderItems,
      };

    case FOLDERS_ACTION_TYPES.UPDATE_FOLDER_NAME_COMPLETED:
      return {
        ...state,
        binName: action.payload.name,
      };

    case FOLDERS_ACTION_TYPES.ADD_STOCK_ITEM_TO_FOLDER_COMPLETED:
      if (typeof action.payload.bins[state.binUniqueId] !== 'undefined') {
        const addedStockItemInfo = {
          checked: false,
          isFavorite: true,
          selectedAcquisitionOption:
            action.payload.addedStockItemInfo.stockItemFormats[0],
          shouldShowSelectCheckbox: true,
          stockItem: action.payload.addedStockItemInfo.stockItem,
          stockItemFormats: action.payload.addedStockItemInfo.stockItemFormats,
        };

        const updatedBinWithNewItems = state.binItems.concat([
          addedStockItemInfo,
        ]);
        const fullBinWithNewItems = state.fullBinItems.concat([
          addedStockItemInfo,
        ]);

        return {
          ...state,
          binItems: updatedBinWithNewItems,
          fullBinItems: fullBinWithNewItems,
        };
      } else {
        return {
          ...state,
          sideMenuPoppedOpen: true,
          poppedOpenFolderUniqueId: Object.keys(action.payload.bins)[0],
        };
      }

    case TYPES.DELETE_FOLDER_CONTENT_FAILED:
      return state;

    case TYPES.FILTER_KEYWORD_CHANGED:
      const keyword = action.payload.trim();

      let filteredBinItems = state.fullBinItems;

      if (keyword.length > 0) {
        filteredBinItems = state.fullBinItems.filter((binItem) => {
          return binItem.stockItem.title
            .toUpperCase()
            .includes(keyword.toUpperCase());
        });
      }

      return {
        ...state,
        binItems: filteredBinItems,
      };

    case TYPES.REORDER_FOLDER_CONTENT:
      if (action.destinationContentId === action.moveContentId) {
        return state;
      }

      return {
        ...state,
        binItems: reorderStockItemsInArray(
          state.binItems,
          action.moveContentId,
          action.destinationContentId,
          action.shouldInsertBefore,
          (item) => item.stockItem.contentId
        ),
        fullBinItems: reorderStockItemsInArray(
          state.fullBinItems,
          action.moveContentId,
          action.destinationContentId,
          action.shouldInsertBefore,
          (item) => item.stockItem.contentId
        ),
      };

    default:
      return state;
  }
}

export default folderReducer;
