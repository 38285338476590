import { createAction } from 'redux-actions';
import { push } from 'redux-first-history';

import CollectionsAPI from '../CollectionsAPI';

export const TYPES = {
  FETCH_COLLECTIONS_RESULTS_STARTED: 'FETCH_COLLECTIONS_RESULTS_STARTED',
  FETCH_COLLECTIONS_RESULTS_FAILED: 'FETCH_COLLECTIONS_RESULTS_FAILED',
  FETCH_COLLECTIONS_RESULTS_COMPLETED: 'FETCH_COLLECTIONS_RESULTS_COMPLETED',
  LOAD_MORE_COLLECTIONS_RESULTS_COMPLETED:
    'LOAD_MORE_COLLECTIONS_RESULTS_COMPLETED',
};

export const fetchCollectionsResultsStarted = createAction(
  TYPES.FETCH_COLLECTIONS_RESULTS_STARTED
);
export const fetchCollectionsResultsFailed = createAction(
  TYPES.FETCH_COLLECTIONS_RESULTS_FAILED,
  (error) => error
);
export const fetchCollectionsResultsCompleted = createAction(
  TYPES.FETCH_COLLECTIONS_RESULTS_COMPLETED
);

export const loadMoreCollectionsResultsCompleted = createAction(
  TYPES.LOAD_MORE_COLLECTIONS_RESULTS_COMPLETED
);

export function fetchCollectionsResults(urlName, page, isLoadMore) {
  return (dispatch) => {
    dispatch(fetchCollectionsResultsStarted({ urlName, page }));

    return CollectionsAPI.get(urlName, page)
      .then((response) => response.json())
      .then((json) => {
        if (isLoadMore) {
          dispatch(loadMoreCollectionsResultsCompleted(json));
        } else {
          dispatch(fetchCollectionsResultsCompleted(json));
        }
        const newUrl = window.location.pathname + '?page=' + page;

        // Update URL for search query *last*
        return dispatch(push(newUrl));
      })
      .catch((e) => dispatch(fetchCollectionsResultsFailed(e)));
  };
}
